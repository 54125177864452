import React, {useContext, useState} from "react";
import {useNavigate, useParams} from 'react-router-dom';

import {useMutation, useQuery} from "@apollo/client";
import {toast} from "react-toastify";
import {Grid, IconButton, Typography} from "@mui/material";
import {mdiShopping} from "@mdi/js";
import Icon from "@mdi/react";

import HeaderComponent from "../components/HeaderComponent";
import ModerationStatusComponent from "../components/ModerationStatusComponent";
import ToastComponent from "../components/ToastComponent";
import ButtonMenuComponent from "../components/ButtonModerationComponent";
import PresentationRecipeWithIconComponent from "../components/PresentationRecipeWithIconComponent";
import ModalComponent from "../components/ModalComponent";
import VerticalNavBarComponent from "../components/VerticalNavBarComponent";
import ButtonComponent from "../components/ButtonComponent";
import MediaComponent from "../components/MediaComponent";

import {convertDate} from "../utils/convertDate";
import {handleErrorMessageAndLogoutIfNecessary} from "../utils/handleErrorMessageAndLogoutIfNecessary";
import {COLORS} from "../constants/colors";
import {GENERAL_TEXT, LIST_TEXT, MODAL_TEXT} from "../constants/text";
import {GET_RECIPE} from "../gql/apiGatewayQueries";
import {API_GATEWAY_API, RECIPE_API} from "../gql/clients";
import {MODERATION_STATUS} from "../constants/moderationStatus";
import {DELETE_RECIPE} from "../gql/recipeQueries";

import {BackOfficeContext} from "../AppRouting";
import Cookies from "js-cookie";

export default function RecipePage(props) {
    const {handleSignOut} = useContext(BackOfficeContext);

    const {isProAccount} = props

    const navigate = useNavigate();

    const {recipeId} = useParams();
    const [recipe, setRecipe] = useState(null)
    const [ingredients, setIngredients] = useState(null)
    const [createdAt, setCreatedAt] = useState("")
    const [moderatedAt, setModeratedAt] = useState("")

    const [moderationStatus, setModerationStatus] = useState({
        text: '',
        color: '',
        opaqueColor: '',
    })

    const [modal, setModal] = useState({
        visible: false,
        moderationReason: '',
    })

    useQuery(GET_RECIPE,
        {
            client: API_GATEWAY_API,
            variables: {
                "getRecipeId": recipeId
            },
            onCompleted: (data) => {
                updateRecipe(data)
            },
            onError: (error) => handleError(error)
        }
    )

    const [deleteRecipe] = useMutation(DELETE_RECIPE, {
        client: RECIPE_API,
        onCompleted: () => {
            navigate('/ourRecipes')
        },
    });

    const deleteMyRecipe = async () => {
        await deleteRecipe({
            variables: {
                deleteRecipeId: recipeId,
            }
        });
    }

    const handleError = (error) => {
        toast.error(handleErrorMessageAndLogoutIfNecessary(error, handleSignOut))
    }

    const updateRecipe = async (data) => {
        await Cookies.remove('isReportRedirect');

        setIngredients(data.getRecipe.ingredients)

        const ingredientsWithoutProductDetails = JSON.parse(JSON.stringify(data.getRecipe.ingredients));

        ingredientsWithoutProductDetails.forEach((el) => {
            if (el.product) {
                el.product = el.product.id;
            }
        });

        const recipeWithoutProductDetails = {...data.getRecipe, ingredients: ingredientsWithoutProductDetails};
        setRecipe(recipeWithoutProductDetails);

        // eslint-disable-next-line default-case
        switch (data.getRecipe.status) {
            case MODERATION_STATUS.pending :
                setModerationStatus({
                    text: GENERAL_TEXT.pendingStatus,
                    color: COLORS.purple,
                    opaqueColor: COLORS.opaquePurple10
                })
                break;
            case MODERATION_STATUS.shelved :
                setModerationStatus({
                    text: GENERAL_TEXT.shelvedStatus,
                    color: COLORS.blueOdeven,
                    opaqueColor: COLORS.opaqueBlueOdeven10
                })
                break;
            case MODERATION_STATUS.valid :
                setModerationStatus({
                    text: GENERAL_TEXT.validatedStatus,
                    color: COLORS.green,
                    opaqueColor: COLORS.opaqueGreen10
                })
                break;
            case MODERATION_STATUS.archived :
                setModerationStatus({
                    text: GENERAL_TEXT.archivedStatus,
                    color: COLORS.grey,
                    opaqueColor: COLORS.opaqueGrey10
                })
                break;
        }
        setCreatedAt(convertDate(data.getRecipe.createdAt))
        data.getRecipe.moderatedAt && setModeratedAt(convertDate(data.getRecipe.moderatedAt))
    }

    const checkIfFirstLetterIsVowelsOrH = (nameOfIngredient) => {
        const vowelsOrH = ["a", "e", "i", "o", "u", "h"]
        const firstLetter = nameOfIngredient.charAt(0).toLowerCase()
        return vowelsOrH.includes(firstLetter) ? " d' " + nameOfIngredient : " de " + nameOfIngredient;
    }

    const DisplayIngredientsManagement = ({el}) => {
        const {unit, quantity, name, product} = el;

        const prefix = quantity ? `${quantity} ` : '';
        const suffix = unit ? `${unit}${checkIfFirstLetterIsVowelsOrH(name)}` : name;

        return (
            <Grid container direction="row" alignItems="center">
                <Typography mt={2} variant="body1" sx={{height: "100%"}}>
                    {prefix}{suffix}
                </Typography>
                {isProAccount && product && (
                    <Grid item ml={0.5}>
                        <IconButton onClick={() => navigate(`/product/${product.id}`)}>
                            <Icon
                                path={mdiShopping}
                                size={1}
                                color={COLORS.orange}
                            />
                        </IconButton>
                    </Grid>
                )}
            </Grid>
        );
    };

    const handleCloseModal = () => {
        setModal({...modal, "visible": false})
    };

    const handleChangeModerationReason = (event) => {
        setModal({...modal, "moderationReason": event.target.value})
    }

    {
        if (recipe) {
            return (
                <>
                    <HeaderComponent/>
                    <Grid container xs direction="row" justifyContent="center" alignItems="flex-start">
                        <Grid container alignItems="center" xs={2}>
                            <VerticalNavBarComponent/>
                        </Grid>
                        <Grid container direction="column" alignItems="center" px={4} xs={10} mt={10}>
                            {!isProAccount &&
                                <Grid container xs justifyContent="space-between" alignItems="center">
                                    <Grid container xs={8} spacing={1}>
                                        <Grid item ml={-1}>
                                            <ModerationStatusComponent
                                                name={`${LIST_TEXT.createdBy} ${recipe.createdBy ? recipe.createdBy.username : "-"} ${GENERAL_TEXT.the} ${createdAt}`}
                                                selected
                                                color={COLORS.orange}
                                                opaqueColor={COLORS.opaqueOrange10}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <ModerationStatusComponent
                                                name={moderationStatus.text}
                                                selected
                                                color={moderationStatus.color}
                                                opaqueColor={moderationStatus.opaqueColor}
                                            />
                                        </Grid>
                                        {recipe.moderatedAt && (
                                            <Grid item>
                                                <ModerationStatusComponent
                                                    name={`${LIST_TEXT.moderatedBy} ${recipe.moderatedBy ? recipe.moderatedBy.username : "-"} ${GENERAL_TEXT.the} ${moderatedAt}`}
                                                    selected
                                                    color={COLORS.purple}
                                                    opaqueColor={COLORS.opaquePurple10}
                                                />
                                            </Grid>
                                        )}
                                        {recipe.status !== MODERATION_STATUS.archived && (
                                            <Grid item>
                                                <ModerationStatusComponent
                                                    name={recipe.public ? GENERAL_TEXT.public : GENERAL_TEXT.private}
                                                    selected
                                                    color={recipe.public ? COLORS.green : COLORS.red}
                                                    opaqueColor={recipe.public ? COLORS.opaqueGreen10 : COLORS.opaqueRed10}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                    <Grid container xs={4} justifyContent="end" spacing={2}>
                                        <Grid item>
                                            <ButtonComponent
                                                variantText
                                                label={GENERAL_TEXT.modify}
                                                onClick={() => navigate(`/editRecipe/${recipe.id}`)}
                                            />
                                        </Grid>
                                            <Grid item>
                                                <ButtonMenuComponent
                                                    recipe={recipe}
                                                    recipeId={recipeId}
                                                    handleError={handleError}
                                                />
                                            </Grid>
                                    </Grid>
                                </Grid>
                            }
                            <Grid container direction="row" mt={isProAccount ? 0 : 2} alignItems="center">
                                <Grid item xs={6} spacing={1} mt={1}>
                                    <Typography variant="h4" color={COLORS.orange}>{recipe.name}</Typography>
                                </Grid>
                                <Grid item xs={6} direction="column">
                                    {isProAccount &&
                                        <Grid container xs justifyContent="end">
                                            <Grid item>
                                                <ButtonComponent
                                                    variantText
                                                    label={GENERAL_TEXT.delete}
                                                    onClick={() => setModal({
                                                        ...modal,
                                                        visible: true,
                                                    })}
                                                />
                                            </Grid>
                                            <Grid item ml={4}>
                                                <ButtonComponent
                                                    label={GENERAL_TEXT.modify}
                                                    onClick={() => navigate(`/editRecipe/${recipe.id}`)}
                                                />
                                            </Grid>
                                        </Grid>
                                    }
                                    <Grid container xs direction="row" justifyContent="end" spacing={1}
                                          mt={isProAccount ? 0.5 : 0}>
                                        <PresentationRecipeWithIconComponent
                                            recipe={recipe}
                                            isProAccount={isProAccount}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container xs py={2} spacing={1}>
                                {recipe.videos?.map((el, index) => (
                                    <Grid item key={index}>
                                        <MediaComponent media={el} isVideo/>
                                    </Grid>
                                ))}
                                {recipe.images?.map((el, index) => (
                                    <Grid item key={index}>
                                        <MediaComponent media={el}/>
                                    </Grid>
                                ))}
                            </Grid>
                            {recipe.description &&
                                <Grid container mb={2} xs={12}>
                                    <Typography variant="body1">{recipe.description}</Typography>
                                </Grid>
                            }
                            <Grid container xs direction="column">
                                <Grid container xs={4}>
                                    <Typography variant="h6">{GENERAL_TEXT.ingredientsTwoPoints}</Typography>
                                    {ingredients?.map((el, index) => (
                                        <DisplayIngredientsManagement el={el} key={`ingredients-${index}`}/>
                                    ))}
                                </Grid>
                                <Grid container xs={8}>
                                    <Grid item xs ml={2}>
                                        <Typography variant="h6">{GENERAL_TEXT.stepsTwoPoints}</Typography>
                                        {recipe.steps.map((el, index) => (
                                            <Grid container direction="row" key={`steps-${index}`}
                                                  alignItems="center">
                                                <Grid item xs alignItems="center">
                                                    <p>{index + 1} - {el.description}</p>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <ToastComponent/>
                    <ModalComponent
                        open={modal.visible}
                        onClose={handleCloseModal}
                        onConfirm={async () => {
                            await deleteMyRecipe()
                            setModal({...modal, "visible": false})
                        }}
                        titleModal={MODAL_TEXT.deleteRecipe}
                        moderationReason={modal.moderationReason}
                        onChangeModerationReason={handleChangeModerationReason}
                    />
                </>
            );
        }
    }
}
