import PropTypes from "prop-types";
import React, {useState} from "react";

import {Grid, Typography} from "@mui/material";
import {useQuery} from "@apollo/client";

import {COLORS} from "../constants/colors";
import {GET_TAGS} from "../gql/recipeQueries";
import {RECIPE_API} from "../gql/clients";

export const SelectionOfTagsComponent = (props) => {
    const {tags, setTags} = props;

    const [listOfTags, setListOfTags] = useState([])

    useQuery(GET_TAGS, {
        client: RECIPE_API,
        onCompleted: (data) => setListOfTags(data.getTags),
    });
    const handleTagPress = (tagId) => {
        let newTags = [...tags];

        if (newTags?.some((tag) => tag === tagId)) {
            newTags = newTags.filter((tag) => tag !== tagId);
        } else {
            newTags = [...newTags, tagId];
        }

        setTags(newTags);
    };

    const tagsValues = Object.values(listOfTags);

    return (
        <Grid container spacing={2} py={4}>
            <Grid container xs spacing={1} justifyContent="flex-start" a direction="row" wrap="wrap">
                {tagsValues?.map((tag) => {
                    const tagSelected = tags.some((selectedTag) => selectedTag === tag.id);

                    return (
                        <Grid item>
                            <Typography
                                variant="body1"
                                border={1}
                                ml={2}
                                px={2}
                                py={1}
                                borderRadius={99}
                                sx={{
                                    color: tagSelected ? COLORS.white : COLORS.orange,
                                    borderColor: tagSelected ? COLORS.orange : COLORS.greyInput,
                                    backgroundColor: tagSelected ? COLORS.orange : COLORS.white,
                                    cursor: "pointer"
                                }}
                                onClick={() => handleTagPress(tag.id)}
                            >
                                {tag.name}
                            </Typography>
                        </Grid>
                    );
                })}
            </Grid>
        </Grid>
    );
};

SelectionOfTagsComponent.propTypes = {
    tags: PropTypes.array.isRequired,
    setTags: PropTypes.func.isRequired,
    listOfTags: PropTypes.object.isRequired,
};
