import PropTypes from "prop-types";

import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import EastIcon from '@mui/icons-material/East';

import {Grid, Typography} from "@mui/material";

import {determineColorBasedOnPercentage} from "../utils/determineColorBasedOnPercentage";
import {GENERAL_TEXT} from "../constants/text";

export default function StatsResultComponent(props) {
    const {number, percentage, text} = props

    const HandleDetermineIcon = () => {
        const sx = {marginRight: 1}

        if (percentage > 2) {
            return <NorthIcon sx={sx}/>
        } else if (percentage < -2) {
            return <SouthIcon sx={sx}/>
        } else {
            return <EastIcon sx={sx}/>
        }
    }

    return (
        <Grid
            px={2}
            py={2}
            container
            xs
            direction="column"
            justifyContent="center"
            alignItems="center"
            boxShadow={1}
            borderRadius={1}
        >
            <Grid
                container
                xs
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Typography variant="h3">{number}</Typography>
                <Typography variant="body2">{text}</Typography>
            </Grid>
            <Grid
                justifyContent="center"
                alignItems="center"
                container
                xs
                style={{color: determineColorBasedOnPercentage(percentage)}}
            >
                <HandleDetermineIcon/>
                <Typography variant="body2">
                    {percentage > 0 && "+"}{percentage}{GENERAL_TEXT.percentageLastPeriod}
                </Typography>
            </Grid>
        </Grid>
    );
}

StatsResultComponent.propTypes = {
    number: PropTypes.number,
    percentage: PropTypes.number,
    text: PropTypes.string,
};
