import PropTypes from "prop-types";

import {Autocomplete, Grid, TextField, Typography} from "@mui/material";

import {COLORS} from "../constants/colors";
import {GENERAL_TEXT} from "../constants/text";

export default function AutoCompleteComponent(props) {
    const {value, label, options, onChange, disabled, error} = props;

    const sxAutoComplete = {
        width: "100%",
        "& .MuiAutocomplete-clearIndicator": {
            color: error ? "red !important" : COLORS.orange,
        },
        "& .MuiAutocomplete-popupIndicator": {
            color: error ? "red !important" : COLORS.orange,
        },
        "&.Mui-disabled .MuiAutocomplete-clearIndicator, &.Mui-disabled .MuiAutocomplete-popupIndicator": {
            color: COLORS.orange,
        },
    }

    const sxTextField = {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: error ? "red !important" : COLORS.orange,
            borderWidth: error ? "2px !important" : "1px !important",
        },
        "& .MuiInputLabel-outlined": {
            color: error ? "red !important" : COLORS.orange,
        }
    }

    return (
        <Grid container position="relative" height="100%" sx={{opacity: disabled ? 0.5 : 1}}>
            <Autocomplete
                value={value}
                disablePortal
                size="small"
                disabled={disabled}
                id="combo-box-demo"
                options={options}
                onChange={onChange}
                sx={sxAutoComplete}
                renderInput={(params) => <TextField sx={sxTextField} {...params} label={label}/>}
            />
            {error &&
                <Grid item position="absolute" bottom={-20} left={18}>
                    <Typography
                        variant="body-2"
                        color="red"
                    >
                        {GENERAL_TEXT.requiredField}
                    </Typography>
                </Grid>
            }
        </Grid>
    );
}

AutoCompleteComponent.propTypes = {
    value: PropTypes.any,
    label: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
};
