import React from "react";
import {Button, Grid, IconButton, Typography} from "@mui/material";
import {Link, useNavigate,} from "react-router-dom";
import LoginSharpIcon from '@mui/icons-material/LoginSharp';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';

import homePageLogo from '../assets/img/homePageLogo.png';
import swipeSmartPhone from '../assets/img/swipeSmartPhone.png';
import appStore from '../assets/img/appStore.png';
import googlePlay from '../assets/img/googlePlay.png';
import swipe from '../assets/img/swipe.png';
import profile from '../assets/img/profile.png';
import newRecipe from '../assets/img/newRecipe.png';
import like from '../assets/img/like.png';
import cook from '../assets/img/cook.png';
import community from '../assets/img/community.png';
import waves from '../assets/img/waves.png';
import headerLogo from '../assets/img/headerLogo.png';
import tiktok from '../assets/img/tiktok.png';

import {CheckIfIsSmartphone} from "../utils/CheckIfIsSmartphone";
import {GENERAL_TEXT, SLOGAN} from "../constants/text";
import {COLORS} from "../constants/colors";

import '../assets/css/pages/HomePage.css';

export default function HomePage() {
    const navigate = useNavigate();

    const isSmartphone = CheckIfIsSmartphone()

    const Header = () => (
        <Grid container justifyContent="space-between" alignItems="center" px={2} py={1}
              sx={{backgroundColor: COLORS.orange, width: "100vw"}}>
            <Link to="/" style={{textDecoration: "none", color: COLORS.white}}>
                <Grid item>
                    <img src={headerLogo} style={{width: "8rem"}}
                         alt={"headerLogo"}/>
                </Grid>
            </Link>
            {!isSmartphone && (
                <Grid item>
                    <Button onClick={handleLoginClick} variant="outlined" size="small" endIcon={<LoginSharpIcon/>}
                            sx={sxButton}>
                        {GENERAL_TEXT.proSpace}
                    </Button>
                </Grid>
            )}
        </Grid>
    );

    const ScreenShot = ({imageSrc, text}) => (
        <Grid item mt={isSmartphone ? 4 : 8} style={styleImage}>
            <img src={imageSrc} style={{maxWidth: maxWidthImageSmartphone}} alt={text}/>
        </Grid>
    );

    const handleLoginClick = () => {
        navigate('/login', {replace: true});
    };

    const handleSocialMediaButtonClick = (url) => {
        window.open(url);
    };

    const determineTextColor = () => {
        return isSmartphone ? COLORS.black : COLORS.white;
    };

    const sxAppStore = {
        width: isSmartphone ? "8rem" : "13rem"
    };

    const sxTikTok = {
        width: "1.5rem"
    };

    const maxWidthImageSmartphone = "15rem";

    const sxButton = {
        backgroundColor: COLORS.orange,
        color: COLORS.white,
        borderColor: COLORS.white,
        '&:hover': {
            backgroundColor: COLORS.orange,
            color: COLORS.white,
            borderColor: COLORS.white,
            opacity: 0.5
        }
    };

    const styleImage = {
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: 20,
        overflow: "hidden"
    };

    const appStoreLink = "https://apps.apple.com/us/app/swipeat/id6448028275";
    const googlePlayLink = "https://play.google.com/store/apps/details?id=fr.odeven.swipeat&gl=FR&pli=1";
    const FBLink = "https://www.facebook.com/profile.php?id=100092366532966&locale=fr_FR";
    const YTLink = "https://www.youtube.com/playlist?list=PLnX0sXmSs26JtIjNZhoOlY7l81peHMiCs";
    const instagramLink = "https://www.instagram.com/swip_eat/";
    const tiktokLink = "https://www.tiktok.com/@swip_eat";
    const odevenLink = "https://odeven.fr/";

    return (
        <>
            <Header/>

            <Grid container justifyContent="center" alignItems="center" mt={isSmartphone ? 0 : 10} px={2} py={4}
                  sx={{width: "100%"}} direction={isSmartphone ? "column-reverse" : "row"}>
                <Grid container item xs={12} lg={8} py={4} px={isSmartphone ? 0 : 6}
                      direction={isSmartphone ? "column-reverse" : "column"} justifyContent="space-around"
                      alignItems="center" sx={{minHeight: isSmartphone ? "40%" : "60%"}}>
                    <Grid container direction="column" justifyContent="center" alignItems="center" mt={2}>
                        <Grid item>
                            <img src={homePageLogo} style={{width: isSmartphone ? "15rem" : "25rem"}}
                                 alt={"homePageLogo"}/>
                        </Grid>
                        <Grid item>
                            <Typography variant="h4" align="center" fontSize={isSmartphone ? "1.2rem" : "1.8rem"} mt={2}
                                        px={2} color={COLORS.black} style={{whiteSpace: 'pre-line'}}>
                                {SLOGAN}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item direction="row" justifyContent="center" alignItems="center"
                          columnSpacing={isSmartphone ? 6 : 10}
                          mt={isSmartphone ? 0 : 4}>
                        <Grid item>
                            <Link to={appStoreLink} target="_blank" style={{textDecoration: "none"}}>
                                <img src={appStore} style={sxAppStore} alt={"appStore"}/>
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link to={googlePlayLink} target="_blank" style={{textDecoration: "none"}}>
                                <img src={googlePlay} style={sxAppStore} alt={"googlePlay"}/>
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12} lg={4} justifyContent="center" alignItems="center">
                    <Grid item>
                        <img src={swipeSmartPhone} style={{maxWidth: "15rem"}} alt={"swipeSmartPhone"}/>
                    </Grid>
                </Grid>
            </Grid>

            <Grid xs={12} px={2} container justifyContent="center" alignItems="center" direction="column" style={{
                backgroundImage: `url(${waves})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                minHeight: '100vh'
            }}>
                <Grid xs={12} px={2} mt={0} container justifyContent="space-around" alignItems="center"
                      direction={isSmartphone ? "column" : "row"}>
                    <Typography variant="h4" align="center" fontSize="1.5rem" color={determineTextColor()}
                                style={{fontFamily: "Pacifico, sans-serif"}}>
                        {GENERAL_TEXT.swipeAndEat}
                    </Typography>
                </Grid>
                <Grid xs={10} container justifyContent="space-around" alignItems="center"
                      direction={isSmartphone ? "column-reverse" : "row"}>
                    {!isSmartphone &&
                        <>
                            <ScreenShot imageSrc={swipe} text="swipe"/>
                            <ScreenShot imageSrc={cook} text="cook"/>
                        </>
                    }
                    <ScreenShot imageSrc={like} text="swipe"/>
                    <Grid container xs={3} px={1} item mt={isSmartphone ? 4 : 8} justifyContent="center">
                        <Typography variant="h4" align="center" fontSize="1.2rem" color={determineTextColor()}
                                    style={{fontFamily: "Pacifico, sans-serif"}}>
                            {GENERAL_TEXT.discover}
                        </Typography>
                        <Typography variant="h4" align="center" fontSize="1rem" color={determineTextColor()} mt={2}
                                    style={{whiteSpace: 'pre-line'}}>
                            {GENERAL_TEXT.discoverText}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid mt={4} xs={12} px={2} container justifyContent="center" alignItems="center" direction="column"
                  mb={isSmartphone ? 5 : 15}>
                <Grid xs={10} container justifyContent="space-around" alignItems="center"
                      direction={isSmartphone ? "column" : "row"}>
                    <Grid container xs={3} px={1} item mt={isSmartphone ? 4 : 8} justifyContent="center">
                        <Typography variant="h4" align="center" fontSize="1.2rem" color={COLORS.orange}
                                    style={{fontFamily: "Pacifico, sans-serif"}}>
                            {GENERAL_TEXT.share}
                        </Typography>
                        <Typography variant="h4" align="center" fontSize="1rem" color={COLORS.black} mt={2}
                                    style={{whiteSpace: 'pre-line'}}>
                            {GENERAL_TEXT.shareText}
                        </Typography>
                    </Grid>
                    <ScreenShot imageSrc={community} text="community"/>
                    {!isSmartphone &&
                        <>
                            <ScreenShot imageSrc={newRecipe} text="newRecipe"/>
                            <ScreenShot imageSrc={profile} text="profile"/>
                        </>
                    }
                </Grid>
            </Grid>
            <Grid container xs direction={{xs: "row", md: "row-reverse"}} justifyContent="center" alignItems="center"
                  p={2}
                  sx={{backgroundColor: COLORS.orange}}>
                <Grid container xs={12} md={6} justifyContent="center" alignItems="center">
                    <Grid item>
                        <Typography
                            variant="body2"
                            fontWeight="fontWeightBold"
                            color={COLORS.white}
                        >
                            {GENERAL_TEXT.findUs}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={() => handleSocialMediaButtonClick(FBLink)}>
                            <FacebookIcon className="home-page-icon"/>
                        </IconButton>
                        <IconButton onClick={() => handleSocialMediaButtonClick(instagramLink)}>
                            <InstagramIcon className="home-page-icon"/>
                        </IconButton>
                        <IconButton onClick={() => handleSocialMediaButtonClick(YTLink)}>
                            <YouTubeIcon className="home-page-icon"/>
                        </IconButton>
                        <IconButton onClick={() => handleSocialMediaButtonClick(tiktokLink)}>
                            <img src={tiktok} style={sxTikTok} alt="tikTok"/>
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container xs={12} md={6} mt={{xs: 2, md: 0}} justifyContent="center" alignItems="center">
                    <Link to="/legal-notice" style={{textDecoration: "none", color: COLORS.white}}>
                        <Typography variant="body2"
                                    fontWeight="fontWeightBold">{GENERAL_TEXT.legalNotice} - {"\u00A0"}</Typography>
                    </Link>
                    <Link to="/privacy-policy" style={{textDecoration: "none", color: COLORS.white}}>
                        <Typography variant="body2"
                                    fontWeight="fontWeightBold">{" "}{GENERAL_TEXT.privacyPolicy} - {"\u00A0"}</Typography>
                    </Link>
                    <Link to="/gcu" style={{textDecoration: "none", color: COLORS.white}}>
                        <Typography variant="body2" fontWeight="fontWeightBold"> {GENERAL_TEXT.cgu}</Typography>
                    </Link>
                </Grid>
                <Link to={odevenLink} target="_blank" style={{textDecoration: "none", color: COLORS.white}}>
                    <Typography color={COLORS.white} mt={1} variant="body2">Designed by Odeven - Odeven SAS
                        2023
                    </Typography>
                </Link>
            </Grid>
        </>
    )
        ;
}

