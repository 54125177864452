import PropTypes from "prop-types";

import {useEffect, useRef} from "react";

import Chart from "chart.js/auto";

import {formatDateString} from "../utils/formatDateString";
import {GENERAL_TEXT} from "../constants/text";

export default function LineChartComponent(props) {
    const {globalStats, prevGlobalStats} = props;

    const chartRef = useRef(null);

    useEffect(() => {
        if (globalStats) {
            createLineChart();
        }
    }, [globalStats, prevGlobalStats]);

    const createLineChart = () => {
        const ctx = document.getElementById("lineChart")

        const style = {
            borderWidth: 1,
            tension: 0.1,
            pointRadius: 0,
            pointHoverRadius: 4,
        }

        if (chartRef.current) {
            chartRef.current.destroy();
        }

        const data = {
            labels: globalStats?.follows.map((el) => formatDateString(el.date)),

            datasets: [
                {
                    label: GENERAL_TEXT.newFollowers,
                    data: globalStats?.follows.map(el => el.value),
                    borderColor: "green",
                    backgroundColor: "green",
                    legend : false,
                    ...style,
                },
                {
                    label: GENERAL_TEXT.newFollowersPrevPer,
                    data: prevGlobalStats?.follows.map(el => el.value),
                    borderColor: "green",
                    backgroundColor: "green",
                    borderDash: [5, 5],
                    ...style
                },
                {
                    label: GENERAL_TEXT.likesRecipes,
                    data: globalStats?.likes.map(el => el.value),
                    borderColor: "orange",
                    backgroundColor: "orange",
                    ...style
                },
                {
                    label: GENERAL_TEXT.likesRecipesPrevPer,
                    data: prevGlobalStats?.likes.map(el => el.value),
                    borderColor: "orange",
                    backgroundColor: "orange",
                    borderDash: [5, 5],
                    ...style
                },
                {
                    label: GENERAL_TEXT.dislikesRecipes,
                    data: globalStats?.dislikes.map(el => el.value),
                    borderColor: "blue",
                    backgroundColor: "blue",
                    ...style
                },
                {
                    label: GENERAL_TEXT.dislikesRecipesPrevPer,
                    data: prevGlobalStats?.dislikes.map(el => el.value),
                    borderColor: "blue",
                    backgroundColor: "blue",
                    borderDash: [5, 5],
                    ...style
                },
            ],
        };

        const config = {
            type: "line",
            data: data,
            options: {
                maintainAspectRatio : false,
                responsive: true,
                hover: {
                    mode: "index",
                    intersect: false,
                },
                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: {
                            precision: 0
                        }
                    }
                }
            },
        };

        chartRef.current = new Chart(ctx, config);
    }

    return (
        <canvas id="lineChart"></canvas>
    );
}

LineChartComponent.propTypes = {
    globalStats: PropTypes.object,
    prevGlobalStats: PropTypes.object,
}
