/** Calculate percentage between prevNumber and number
 *
 * @param {number} prevNumber
 * @param {number} number
 * @return {number}
 */
export const calculatePercentageChange = (prevNumber, number) => {
    if (prevNumber !== 0) {
        const percentage = ((number - prevNumber) / prevNumber) * 100;
        const formattedPercentage = percentage.toFixed(1);
        return parseFloat(formattedPercentage)
    }

    return 0
};