import {gql} from "@apollo/client";

export const UPLOAD_FILE = gql`
 mutation UploadFile($input: UploadFileInput!) {
  uploadFile(input: $input) {
    id
    publicUrl
  }
}
`;

