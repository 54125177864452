import React, {useContext, useState} from "react";

import {useMutation, useQuery} from "@apollo/client";
import {toast} from "react-toastify";
import {Grid} from "@mui/material";

import HeaderComponent from "../components/HeaderComponent";

import DataGridReportsComponent from "../components/DataGridReportsComponent";
import ToastComponent from "../components/ToastComponent";
import VerticalNavBarComponent from "../components/VerticalNavBarComponent";
import ModalReportComponent from "../components/ModalReportComponent";

import {handleErrorMessageAndLogoutIfNecessary} from "../utils/handleErrorMessageAndLogoutIfNecessary";

import {MODAL_TEXT} from "../constants/text";
import {GET_REVIEW_REPORTS} from "../gql/apiGatewayQueries";
import {API_GATEWAY_API, RECIPE_API} from "../gql/clients";
import {DELETE_REPORT, DELETE_REVIEW} from "../gql/recipeQueries";

import {BackOfficeContext} from "../AppRouting";

export default function ListOfReportsPage() {
    const {handleSignOut} = useContext(BackOfficeContext);

    const [reports, setReports] = useState([]);
    const [selectedReport, setSelectedReport] = useState(null)
    const [showModalReport, setShowModalReport] = useState(false)

    useQuery(GET_REVIEW_REPORTS, {
        client: API_GATEWAY_API,
        onCompleted: (data) => {
            setReports(data.getReports);
        },
        onError: (error) => toast.error(handleErrorMessageAndLogoutIfNecessary(error, handleSignOut)),
    });

    const [deleteReport, {loading: deleteReportLoading}] = useMutation(DELETE_REPORT, {
        client: RECIPE_API,
        onCompleted: () => handleCloseModal(true),
        onError: (error) => toast.error(handleErrorMessageAndLogoutIfNecessary(error, handleSignOut)),
    });

    const [deleteReview, {loading: deleteReviewLoading}] = useMutation(DELETE_REVIEW, {
        client: RECIPE_API,
        onCompleted: () => handleCloseModal(),
        onError: (error) => toast.error(handleErrorMessageAndLogoutIfNecessary(error, handleSignOut)),
    });

    const handleSetSelectReport = async (item) => {
        setSelectedReport(item)
        setShowModalReport(true)
    }

    const handleCloseModal = async (deleteReport) => {
        if (deleteReport) {
            const reportToBeDeleted = selectedReport
            const newReport = reports.filter(report => report.id !== reportToBeDeleted.id)
            setReports(newReport)
        }

        setShowModalReport(false)
        setSelectedReport(null)
    }

    const processReport = async (deleteAlsoReview) => {
        const reportId = selectedReport.id
        const reviewId = selectedReport.review.id

        await deleteReport({
            variables: {
                deleteReportId: reportId
            }
        })

        if (deleteAlsoReview) {
            await deleteReview({
                variables: {
                    deleteReviewId: reviewId
                }
            })
        }
    }

    return (
        reports && (
            <>
                <HeaderComponent/>
                <Grid container xs direction="row" justifyContent="center" alignItems="flex-start">
                    <Grid container alignItems="center" xs={2}>
                        <VerticalNavBarComponent/>
                    </Grid>
                    <Grid container direction="column" alignItems="center" px={2} xs={10} mt={10}>
                        <Grid container xs mt={2}>
                            <DataGridReportsComponent
                                data={reports || []}
                                handleSetSelectReport={handleSetSelectReport}
                            />
                        </Grid>
                    </Grid>
                    <ToastComponent/>
                    {selectedReport &&
                        <ModalReportComponent
                            open={showModalReport}
                            onClose={handleCloseModal}
                            processReport={processReport}
                            selectedReport={selectedReport}
                            titleModal={MODAL_TEXT.confirmNotification}
                            deleteReportLoading={deleteReportLoading}
                            deleteReviewLoading={deleteReviewLoading}
                        />
                    }
                </Grid>
                <ToastComponent/>
            </>
        )
    );
}
