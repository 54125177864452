import React, {useContext, useEffect, useState} from "react";

import Cookies from "js-cookie";
import {Grid, Typography} from "@mui/material";

import AvatarMenuComponent from "./AvatarMenuComponent";

import {COLORS} from "../constants/colors";
import headerLogo from "../assets/img/headerLogo.png";

import {BackOfficeContext} from "../AppRouting";

export default function HeaderComponent() {
    const {userRoles} = useContext(BackOfficeContext);

    const [username, setUsername] = useState(null)

    useEffect(() => {
        setUsername(Cookies.get('username'))
    }, [])

    return (
        <Grid
            zIndex={1000}
            container
            width="100vw"
            justifyContent="space-between"
            alignItems="center"
            position="absolute"
            top={0}
            right={0}
            bgcolor={COLORS.orange}
            pt={1}
            pb={1}
        >
            <Grid item ml={2}>
                <img src={headerLogo} style={{width:  "8rem"}}
                     alt={"headerLogo"}/>
            </Grid>
            <Grid item>
                <Grid container justifyContent="center" alignItems="center">
                    {userRoles.rolePro &&
                        <Typography variant="body1" mr={0.5} color={COLORS.white} fontWeight="bold">
                            {username}
                        </Typography>
                    }
                    <AvatarMenuComponent/>
                </Grid>
            </Grid>
        </Grid>
    );
}

