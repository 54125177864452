import PropTypes from "prop-types";
import {useCallback} from "react";

import {DataGrid, frFR, GridRowSpacingParams} from '@mui/x-data-grid';
import {useNavigate} from "react-router-dom";
import {Avatar} from "@mui/material";

import {GENERAL_TEXT, LIST_TEXT} from "../constants/text";

export default function DataGridProductsComponent(props) {
    const {data} = props;

    const navigate = useNavigate()

    const columns = [
        {
            field: 'picture',
            headerName: '',
            flex: 3,
            sortable: false,
            renderCell: (params) => {
                return <Avatar
                    sx={{width: "100%", height: "100%"}}
                    variant="square"
                    src={params.row.node.image.publicUrl}
                />
            },
        },
        {
            field: 'name',
            headerName: LIST_TEXT.name,
            flex: 3,
            sortable: true,
            valueGetter: (params) =>
                `${params.row.node.name || '-'}`,
        },
        {
            field: 'description',
            headerName: GENERAL_TEXT.description,
            flex: 6,
            sortable: true,
            valueGetter: (params) =>
                params.row.node.description
        },
    ];

    const getRowSpacing = useCallback((params: GridRowSpacingParams) => {
        return {
            top: params.isFirstVisible ? 0 : 3,
            bottom: params.isLastVisible ? 0 : 3,
        };
    }, []);

    return (
        <DataGrid
            getRowId={() => Math.random()}
            rows={data}
            columns={columns}
            disableColumnMenu
            getRowSpacing={getRowSpacing}
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
            sx={{width: "100%", minHeight: "80vh"}}
            onRowClick={(params) => (navigate(`/product/${params.row.node.id}`))}
        />
    );
}

DataGridProductsComponent.propTypes = {
    data: PropTypes.array,
}
