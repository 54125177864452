import React, {useContext, useEffect, useState} from "react";

import Cookies from "js-cookie";
import {Avatar, Grid, IconButton, Menu, MenuItem, Typography,} from "@mui/material";
import {deepPurple} from "@mui/material/colors";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {GENERAL_TEXT} from "../constants/text";
import {COLORS} from "../constants/colors";

import {BackOfficeContext} from "../AppRouting";

export default function AvatarMenuComponent() {
    const {handleSignOut, userRoles} = useContext(BackOfficeContext);

    const [anchorEl, setAnchorEl] = useState(null);
    const [firstChar, setFirstChar] = useState("")
    const [profilePicture, setProfilePicture] = useState(null)

    useEffect(() => {
        setFirstChar(Cookies.get('firstChar'))
        setProfilePicture(Cookies.get('profilePicture'))
    }, [])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Grid item mr={2}>
            <IconButton
                variant="contained"
                onClick={handleClick}
                size="small"
            >
                {userRoles.rolePro &&
                    <Grid container mr={1}>
                        <KeyboardArrowDownIcon fontSize="small" sx={{color: COLORS.white}}/>
                    </Grid>
                }
                <Avatar
                    sx={{
                        bgcolor: deepPurple[500],
                        width: "2rem",
                        height: "2rem",
                        fontSize: "1rem"
                    }}>
                    {(userRoles.rolePro && profilePicture) ?
                        <img
                            src={profilePicture}
                            style={{width: '100%', height: '100%'}}
                            alt={`profilePicture`}
                        />
                        :
                        <Typography variant="body1">
                            {firstChar}
                        </Typography>
                    }
                </Avatar>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleClose}
                getContentAnchorEl={null}
            >
                <MenuItem onClick={() => handleSignOut()}>
                    {GENERAL_TEXT.logout}
                </MenuItem>
            </Menu>
        </Grid>
    );
}
