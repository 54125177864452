import React, {useContext, useEffect, useState} from "react";

import {useQuery} from "@apollo/client";
import {toast} from "react-toastify";
import {Grid} from "@mui/material";
import {useNavigate} from "react-router-dom";

import HeaderComponent from "../components/HeaderComponent";
import TextFieldComponent from "../components/TextFieldComponent";
import ModerationStatusComponent from "../components/ModerationStatusComponent";
import DataGridRecipesComponent from "../components/DataGridRecipesComponent";
import ToastComponent from "../components/ToastComponent";
import VerticalNavBarComponent from "../components/VerticalNavBarComponent";
import ButtonComponent from "../components/ButtonComponent";

import {handleErrorMessageAndLogoutIfNecessary} from "../utils/handleErrorMessageAndLogoutIfNecessary";
import {GENERAL_TEXT} from "../constants/text";
import {COLORS} from "../constants/colors";
import {SEARCH_RECIPES} from "../gql/apiGatewayQueries";
import {API_GATEWAY_API} from "../gql/clients";
import {MODERATION_STATUS} from "../constants/moderationStatus";

import {BackOfficeContext} from "../AppRouting";
import SelectComponent from "../components/SelectComponent";

export default function ListOfRecipesPage(props) {
    const {isProAccount} = props

    const {showRecipeWithStatus, setShowRecipeWithStatus, handleSignOut} = useContext(BackOfficeContext);

    const navigate = useNavigate();

    const [recipes, setRecipes] = useState([]);
    const [filteredRecipes, setFilteredRecipes] = useState([]);
    const [searchParam, setSearchParam] = useState("");
    const [visibility, setVisibility] = useState(isProAccount ? null : true);

    const [numberOfRecipes, setNumberOfRecipes] = useState({
        [MODERATION_STATUS.pending]: 0,
        [MODERATION_STATUS.shelved]: 0,
        [MODERATION_STATUS.valid]: 0,
        [MODERATION_STATUS.archived]: 0,
    });

    const ITEMS_VISIBILITY = [
        {label: GENERAL_TEXT.all, value: null},
        {label: GENERAL_TEXT.private, value: false},
        {label: GENERAL_TEXT.public, value: true},
    ]

    useQuery(SEARCH_RECIPES, {
        variables: {
            mine: isProAccount
        },
        client: API_GATEWAY_API,
        onCompleted: (data) => {
            updateRecipes(data);
        },
        onError: (error) => toast.error(handleErrorMessageAndLogoutIfNecessary(error, handleSignOut)),
    });

    useEffect(() => {
        setSearchParam("");

        if (isProAccount) {
            setShowRecipeWithStatus({
                [MODERATION_STATUS.pending]: true,
                [MODERATION_STATUS.shelved]: true,
                [MODERATION_STATUS.valid]: true,
                [MODERATION_STATUS.archived]: false,
            });
        }
    }, []);

    useEffect(() => {
        filterRecipes();
    }, [showRecipeWithStatus, searchParam, visibility]);

    const updateRecipes = (data) => {

        setNumberOfRecipes({
            [MODERATION_STATUS.pending]: countRecipesByStatus(
                data.searchRecipes.edges,
                MODERATION_STATUS.pending
            ),
            [MODERATION_STATUS.shelved]: countRecipesByStatus(
                data.searchRecipes.edges,
                MODERATION_STATUS.shelved
            ),
            [MODERATION_STATUS.valid]: countRecipesByStatus(
                data.searchRecipes.edges,
                MODERATION_STATUS.valid
            ),
            [MODERATION_STATUS.archived]: countRecipesByStatus(
                data.searchRecipes.edges,
                MODERATION_STATUS.archived
            ),
        });

        let filteredRecipes = data.searchRecipes.edges.filter(
            (item) =>
                (showRecipeWithStatus.PENDING && item.node.status === MODERATION_STATUS.pending) ||
                (showRecipeWithStatus.SHELVED && item.node.status === MODERATION_STATUS.shelved) ||
                (showRecipeWithStatus.VALID && item.node.status === MODERATION_STATUS.valid) ||
                (showRecipeWithStatus.ARCHIVED && item.node.status === MODERATION_STATUS.archived)
        )

        if (visibility !== null) {
            filteredRecipes = filteredRecipes.filter((item) => item.node.public === visibility)
        }

        setFilteredRecipes(filteredRecipes)
        setRecipes(data.searchRecipes.edges);
    };
    const filterRecipes = () => {
        const searchWords = searchParam
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .split(" ");

        let filteredRecipes = recipes.filter((item) =>
            ((showRecipeWithStatus.PENDING && item.node.status === MODERATION_STATUS.pending) ||
                (showRecipeWithStatus.SHELVED && item.node.status === MODERATION_STATUS.shelved) ||
                (showRecipeWithStatus.VALID && item.node.status === MODERATION_STATUS.valid) ||
                (showRecipeWithStatus.ARCHIVED && item.node.status === MODERATION_STATUS.archived)) &&
            searchWords.every(
                (word) =>
                    item.node.name
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .toLowerCase()
                        .includes(word) ||
                    item.node.id
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .toLowerCase()
                        .includes(word)
            )
        );

        if (visibility !== null) {
            filteredRecipes = filteredRecipes.filter((item) => item.node.public === visibility)
        }

        setFilteredRecipes(filteredRecipes);
    };

    const countRecipesByStatus = (recipesData, status) => {
        return recipesData.filter((el) => el.node.status === status).length;
    };

    const onChangeSearchValue = (event) => {
        setSearchParam(event.target.value);
    };

    const onChangeFilter = (status) => {
        if (status === MODERATION_STATUS.archived) {
            setShowRecipeWithStatus({
                [MODERATION_STATUS.pending]: false,
                [MODERATION_STATUS.shelved]: false,
                [MODERATION_STATUS.valid]: false,
                [MODERATION_STATUS.archived]: !showRecipeWithStatus[MODERATION_STATUS.archived],
            });
        } else {
            setShowRecipeWithStatus({
                ...showRecipeWithStatus,
                [status]: !showRecipeWithStatus[status],
                [MODERATION_STATUS.archived]: false,
            });
        }
    };

    const onChangeVisibility = (event) => {
        const value = event.target.value
        setVisibility(value)
    };

    return (
        filteredRecipes && (
            <>
                <HeaderComponent/>
                <Grid container xs direction="row" justifyContent="center" alignItems="flex-start">
                    <Grid container alignItems="center" xs={2}>
                        <VerticalNavBarComponent/>
                    </Grid>
                    <Grid container direction="column" alignItems="center" px={2} xs={10} mt={10}>
                        <Grid container xs alignItems="center"
                              justifyContent={isProAccount ? "space-between" : "flex-start"}>
                            <Grid container xs={8}>
                                <Grid item>
                                    <TextFieldComponent
                                        id="outlined-basic"
                                        label={GENERAL_TEXT.searchBar}
                                        value={searchParam}
                                        onChange={onChangeSearchValue}
                                        width="15vw"
                                        searchBar
                                    />
                                </Grid>
                                <Grid item ml={1} xs={2}>
                                    <SelectComponent
                                        label={GENERAL_TEXT.visibility}
                                        value={visibility}
                                        onChange={(event) => onChangeVisibility(event)}
                                        data={ITEMS_VISIBILITY}
                                    />
                                </Grid>
                            </Grid>
                            {isProAccount &&
                                <Grid item>
                                    <ButtonComponent
                                        label={GENERAL_TEXT.add}
                                        onClick={() => navigate(`/editRecipe/${"newRecipe"}`)}
                                    />
                                </Grid>
                            }
                        </Grid>
                        {!isProAccount &&
                            <Grid xs container direction="row" justifyContent="space-between" mt={2}>
                                <Grid container xs={8} direction="row">
                                    <Grid item>
                                        <ModerationStatusComponent
                                            name={GENERAL_TEXT.pendingStatus}
                                            number={numberOfRecipes.PENDING}
                                            selected={showRecipeWithStatus.PENDING}
                                            onClick={() => onChangeFilter(MODERATION_STATUS.pending)}
                                            color={COLORS.purple}
                                            opaqueColor={COLORS.opaquePurple60}
                                            showNumber
                                        />
                                    </Grid>
                                    <Grid item ml={1}>
                                        <ModerationStatusComponent
                                            name={GENERAL_TEXT.shelvedStatus}
                                            number={numberOfRecipes.SHELVED}
                                            selected={showRecipeWithStatus.SHELVED}
                                            onClick={() => onChangeFilter(MODERATION_STATUS.shelved)}
                                            color={COLORS.blueOdeven}
                                            opaqueColor={COLORS.opaqueBlueOdeven60}
                                            showNumber
                                        />
                                    </Grid>
                                    <Grid item ml={1}>
                                        <ModerationStatusComponent
                                            name={GENERAL_TEXT.validatedStatus}
                                            number={numberOfRecipes.VALID}
                                            selected={showRecipeWithStatus.VALID}
                                            onClick={() => onChangeFilter(MODERATION_STATUS.valid)}
                                            color={COLORS.green}
                                            opaqueColor={COLORS.opaqueGreen60}
                                            showNumber
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container xs={4} justifyContent="end">
                                    <Grid item>
                                        <ModerationStatusComponent
                                            name={GENERAL_TEXT.archivedStatus}
                                            number={numberOfRecipes.ARCHIVED}
                                            selected={showRecipeWithStatus.ARCHIVED}
                                            onClick={() => onChangeFilter(MODERATION_STATUS.archived)}
                                            color={COLORS.grey}
                                            opaqueColor={COLORS.opaqueGrey60}
                                            showNumber
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        <Grid container xs mt={2}>
                            <DataGridRecipesComponent
                                data={filteredRecipes}
                                isProAccount={isProAccount}
                            />
                        </Grid>
                    </Grid>
                    <ToastComponent/>
                </Grid>
            </>
        )
    );
}
