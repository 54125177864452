/** Check roles of user
 *
 * @param {array} rolesToCheck
 * @returns {boolean}
 */
export const checkRoleAccount = (rolesToCheck) => {
    const userRoles = {
        rolePro: false,
        roleAdmin: false
    }

    if (rolesToCheck?.length > 0) {
        userRoles.rolePro = rolesToCheck.includes("ROLE_PRO")
        userRoles.roleAdmin = rolesToCheck.includes("ROLE_ADMIN")
    }

    return userRoles
};


