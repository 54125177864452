import React, {useContext, useEffect, useState} from "react";

import {useLazyQuery, useMutation} from "@apollo/client";
import {Grid} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";

import HeaderComponent from "../components/HeaderComponent";
import ToastComponent from "../components/ToastComponent";
import VerticalNavBarComponent from "../components/VerticalNavBarComponent";
import ButtonComponent from "../components/ButtonComponent";
import TextFieldComponent from "../components/TextFieldComponent";
import {MediaPickerComponent} from "../components/MediaPickerComponent";

import {handleErrorMessageAndLogoutIfNecessary} from "../utils/handleErrorMessageAndLogoutIfNecessary";
import {CREATE_PRODUCT, UPDATE_PRODUCT} from "../gql/recipeQueries";
import {API_GATEWAY_API, RECIPE_API} from "../gql/clients";
import {GET_PRODUCT} from "../gql/apiGatewayQueries";
import {GENERAL_TEXT} from "../constants/text";

import {BackOfficeContext} from "../AppRouting";

export default function EditProductPage() {
    const {handleSignOut} = useContext(BackOfficeContext);

    const navigate = useNavigate();
    const {productId} = useParams();

    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [link, setLink] = useState("")
    const [image, setImage] = useState([]);

    const [selectedImages, setSelectedImages] = useState([]);
    const [checkError, setCheckError] = useState(false);

    const [loadingDrive, setLoadingDrive] = useState(false)

    const [formValidation, setFormValidation] = useState({
        name: false,
        description: false,
        link: false,
    })

    const [getProduct] = useLazyQuery(GET_PRODUCT,
        {
            client: API_GATEWAY_API,
            variables: {
                "getProductId": productId
            },
            onCompleted: (data) => {
                updateProductToModify(data.getProduct)
            },
            onError: (error) => toast.error(handleErrorMessageAndLogoutIfNecessary(error, handleSignOut)),
        }
    )

    const [createProduct, {loading: loadingCreateProduct}] = useMutation(CREATE_PRODUCT, {
        client: RECIPE_API,
        onCompleted: () => navigate('/ourProducts'),
        onError: (error) => toast.error(handleErrorMessageAndLogoutIfNecessary(error, handleSignOut)),
    });

    const [updateProduct, {loading: loadingUpdateProduct}] = useMutation(UPDATE_PRODUCT, {
        client: RECIPE_API,
        onCompleted: () => navigate(`/product/${productId}`),
        onError: (error) => toast.error(handleErrorMessageAndLogoutIfNecessary(error, handleSignOut)),
    });

    useEffect(() => {
        const getData = async () => {
            await getProduct()
        }
        if (productId !== "newProduct") {
            getData()
        }
    }, [])

    const updateProductToModify = (data) => {
        setName(data.name)
        setImage([data.imageId])
        setSelectedImages([data.image])
        setLink(data.link)
        setDescription(data.description)

        setFormValidation({
            name: true,
            description: true,
            link: true,
        })
    }


    const handleChangeInput = (event, setData, inputName, isLink) => {
        setData(event.target.value)

        let isURLValid = false
        if (isLink) {
            isURLValid = isValidURL(event.target.value);
        }

        setFormValidation((prevFormValidationStep) => ({
            ...prevFormValidationStep,
            [inputName]: isLink ? isURLValid : event.target.value.length > 1,
        }));
    }

    const isValidURL = (text) => {
        const urlPattern = new RegExp(
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
        );

        return urlPattern.test(text);
    };

    const formIsValid = () => {
        return Object.values(formValidation).every((value) => value === true) &&
            (image.length !== 0 && image.length === selectedImages.length)
    };

    const handleConfirm = async () => {
        if (formIsValid()) {
            const variables = {
                input: {
                    name: name,
                    description: description,
                    image: image[0],
                    link: link,
                },
            };
            if (productId === "newProduct") {
                await createProduct({variables})
            } else {
                variables.input.id = productId;
                await updateProduct({variables})
            }
        } else {
            setCheckError(true)
        }
    };

    return (
        <>
            <HeaderComponent/>
            <Grid container xs direction="row" justifyContent="center" alignItems="flex-start">
                <Grid container alignItems="center" xs={2}>
                    <VerticalNavBarComponent/>
                </Grid>
                <Grid container direction="column" alignItems="center" px={2} xs={10} mt={10}>
                    <Grid container xs={12} direction="column" spacing={6}>
                        <Grid item>
                            <TextFieldComponent
                                id="productName"
                                label={GENERAL_TEXT.name}
                                value={name}
                                onChange={(event) => handleChangeInput(event, setName, "name")}
                                maxLength={256}
                                error={checkError && !formValidation.name}
                            />
                        </Grid>
                        <Grid item>
                            <TextFieldComponent
                                id="productDescription"
                                label={GENERAL_TEXT.description}
                                value={description}
                                onChange={(event) => handleChangeInput(event, setDescription, "description")}
                                multiline
                                rows={5}
                                maxLength={512}
                                error={checkError && !formValidation.description}
                            />
                        </Grid>
                        <Grid item>
                            <TextFieldComponent
                                id="productLink"
                                label={GENERAL_TEXT.link}
                                value={link}
                                onChange={(event) => handleChangeInput(event, setLink, "link", true)}
                                maxLength={2048}
                                error={checkError && !formValidation.link}
                                link
                            />
                        </Grid>
                        <Grid item mt={2}>
                            <MediaPickerComponent
                                numberMaxOfMedias={1}
                                selectedMedias={selectedImages}
                                setSelectedMedias={setSelectedImages}
                                setMedias={setImage}
                                error={checkError && image.length === 0}
                                setLoadingDrive={setLoadingDrive}
                            />
                        </Grid>
                        <Grid item>
                            <ButtonComponent
                                variant="contained"
                                onClick={() => handleConfirm()}
                                label={GENERAL_TEXT.confirm}
                                disabled={loadingDrive}
                                loading={loadingCreateProduct || loadingUpdateProduct}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <ToastComponent/>
            </Grid>
        </>
    );
}
