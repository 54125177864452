import PropTypes from "prop-types";

import {styled} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';

import {COLORS} from "../constants/colors";

const CSSButton = styled(LoadingButton)(() => ({
    color: COLORS.white,
    borderColor: COLORS.white,
    backgroundColor: COLORS.orange,
    borderRadius: 8,
    paddingRight: 50,
    paddingLeft: 50,
    paddingTop: 5,
    paddingBottom: 5,
    '&:hover': {
        backgroundColor: COLORS.whiteOrange,
        borderColor: COLORS.orange,
        color: COLORS.orange,
    },
}));

const sxButtonText = {
    color: COLORS.orange,
    backgroundColor: COLORS.white,
    paddingRight: 3,
    paddingLeft: 3,
    paddingTop: 0.7,
    paddingBottom: 0.7,
    '&:hover': {
        backgroundColor: COLORS.opaqueOrange10,
        color: COLORS.orange,
    }
}


export default function ButtonComponent(props) {
    const {label, onClick, size, loading, endIcon, disabled, variantText} = props

    return (
        <CSSButton
            disabled={disabled}
            variant={variantText ? "text" : "outlined"}
            onClick={onClick}
            size={size || "small"}
            loading={loading}
            sx={variantText ? sxButtonText : {}}
            endIcon={endIcon}
        >
            {label}
        </CSSButton>
    );
}

ButtonComponent.propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    size: PropTypes.string,
    loading: PropTypes.bool,
    endIcon: PropTypes.object,
    disabled: PropTypes.bool,
    variantText: PropTypes.bool,
}