import {createContext} from "react";

import {BrowserRouter as Router} from 'react-router-dom';

import AppRouting from "./AppRouting";

export const BackOfficeContext = createContext();

export default function App() {
    return (
        <Router>
                <AppRouting/>
        </Router>
    );
}

