import {TEXT_REPORT_REASON} from "./text";

export const REPORT_REASON_ITEMS = [
    {
        value: "INAPPROPRIATE_CONTENT",
        label: TEXT_REPORT_REASON.labelInappropriateContent,
        message: TEXT_REPORT_REASON.messageInappropriateContent
    },
    {
        value: "SPAM",
        label: TEXT_REPORT_REASON.labelSpam,
        message: TEXT_REPORT_REASON.messageSpam
    },
    {
        value: "FAKE_NEWS",
        label: TEXT_REPORT_REASON.labelFakeNews,
        message: TEXT_REPORT_REASON.messageFakeNews
    },
    {
        value: "HARASSMENT",
        label: TEXT_REPORT_REASON.labelHarassment,
        message: TEXT_REPORT_REASON.messageHarassment
    },
    {
        value: "OFF_TOPIC_CONTENT",
        label: TEXT_REPORT_REASON.labelOffTopicContent,
        message: TEXT_REPORT_REASON.messageOffTopicContent
    }
]