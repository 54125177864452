import PropTypes from "prop-types";

import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import 'dayjs/locale/fr'
import {COLORS} from "../constants/colors";

dayjs.locale('fr')

export default function DatePickerComponent(props) {
    const {label, value, onChange} = props;

    const minDate = dayjs("2023-05-18");

    return (
        <LocalizationProvider adapterLocale="fr" dateAdapter={AdapterDayjs}>
            <DatePicker
                label={label}
                value={value}
                onChange={onChange}
                disableFuture
                minDate={minDate}
                closeOnSelect
                slotProps={{textField: {size: 'small'}}}
                sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6F7C91 !important",
                        borderRadius: "8px !important",
                    },
                    "& .MuiInputBase-input": {
                        color: "#6F7C91 !important"
                    },
                    "& .MuiIconButton-root": {
                        color: COLORS.grey,
                    },
                    "& .MuiInputLabel-outlined": {
                        color: "#6F7C91 !important"
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                        color: "#6F7C91 !important"
                    }
                }}
            />
        </LocalizationProvider>
    );
}

DatePickerComponent.propTypes = {
    value: PropTypes.any,
    label: PropTypes.string,
    onChange: PropTypes.func,
}
