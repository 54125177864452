import React, {useContext, useState} from "react";
import {Link, useNavigate, useParams} from 'react-router-dom';

import {useMutation, useQuery} from "@apollo/client";
import {Grid, Typography} from "@mui/material";

import HeaderComponent from "../components/HeaderComponent";
import ToastComponent from "../components/ToastComponent";
import ModalComponent from "../components/ModalComponent";
import VerticalNavBarComponent from "../components/VerticalNavBarComponent";
import ButtonComponent from "../components/ButtonComponent";

import {handleErrorMessageAndLogoutIfNecessary} from "../utils/handleErrorMessageAndLogoutIfNecessary";
import {COLORS} from "../constants/colors";
import {GENERAL_TEXT, MODAL_TEXT} from "../constants/text";
import {GET_PRODUCT} from "../gql/apiGatewayQueries";
import {API_GATEWAY_API, RECIPE_API} from "../gql/clients";
import {DELETE_PRODUCT} from "../gql/recipeQueries";

import {BackOfficeContext} from "../AppRouting";
import {toast} from "react-toastify";

export default function ProductPage() {
    const {handleSignOut} = useContext(BackOfficeContext);

    const navigate = useNavigate();

    const {productId} = useParams();
    const [product, setProduct] = useState({})

    const [modal, setModal] = useState({
        visible: false,
    })

    const {loading} = useQuery(GET_PRODUCT,
        {
            client: API_GATEWAY_API,
            variables: {
                "getProductId": productId
            },
            onCompleted: (data) => {
                setProduct(data.getProduct)
            },
            onError: (error) => toast.error(handleErrorMessageAndLogoutIfNecessary(error, handleSignOut))
        }
    )

    const [deleteProduct] = useMutation(DELETE_PRODUCT, {
        client: RECIPE_API,
        onCompleted: () => {
            navigate('/ourProducts')
        },
        onError: (error) => toast.error(handleErrorMessageAndLogoutIfNecessary(error, handleSignOut))
    });

    const handleCloseModal = () => {
        setModal({...modal, "visible": false})
    };

    const handleConfirm = async () => {
        setModal({...modal, "visible": false})

        await deleteProduct({
            variables: {
                deleteProductId: productId,
            }
        });
    };

    {
        if (!loading) {
            return (
                <>
                    <HeaderComponent/>
                    <Grid container xs direction="row" justifyContent="center" alignItems="flex-start">
                        <Grid container alignItems="center" xs={2}>
                            <VerticalNavBarComponent/>
                        </Grid>
                        <Grid container direction="column" alignItems="center" px={4} xs={10} mt={10}>
                            <Grid container direction="row" alignItems="center">
                                <Grid item xs={6} spacing={1}>
                                    <Typography variant="h4" color={COLORS.orange}>{product.name}</Typography>
                                </Grid>
                                <Grid item xs={6} direction="column">
                                    <Grid container xs justifyContent="end">
                                        <Grid item>
                                            <ButtonComponent
                                                variantText
                                                label={GENERAL_TEXT.delete}
                                                onClick={() => setModal({
                                                    ...modal,
                                                    visible: true,
                                                })}
                                            />
                                        </Grid>
                                        <Grid item ml={4}>
                                            <ButtonComponent
                                                label={GENERAL_TEXT.modify}
                                                onClick={() => navigate(`/editProduct/${product.id}`)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container xs mt={1} mb={1} spacing={1}>
                                <Grid item>
                                    <img
                                        src={product.image.publicUrl}
                                        style={{
                                            borderRadius: 8,
                                            width: "25vw",
                                            marginBottom: "1vw",
                                        }}
                                        alt={"productImage"}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container mb={2} xs={12}>
                                <Link to={product.link} target="_blank" style={{textDecoration: "none"}}>
                                    <Typography variant="body2" color={COLORS.orange}>
                                        {product.link}
                                    </Typography>
                                </Link>
                            </Grid>
                            <Grid container mb={2} xs={12}>
                                <Typography variant="body1">{product.description}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <ToastComponent/>
                    <ModalComponent
                        open={modal.visible}
                        onClose={handleCloseModal}
                        onConfirm={handleConfirm}
                        titleModal={MODAL_TEXT.deleteProduct}
                    />
                </>
            );
        }
    }
}
