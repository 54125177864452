import PropTypes from "prop-types";
import {useCallback} from "react";

import {DataGrid, frFR} from '@mui/x-data-grid';
import {GridRowSpacingParams} from "@mui/x-data-grid";

import {formatDateString} from "../utils/formatDateString";
import {REPORT_REASON_ITEMS} from "../constants/reportReasons";
import {GENERAL_TEXT} from "../constants/text";

export default function DataGridReportsComponent(props) {
    const {data, handleSetSelectReport} = props;

    const getReasonLabel = (reason) => {
        return REPORT_REASON_ITEMS.find((el) => el.value === reason).label
    }

    const columns = [
        {
            field: 'reason',
            headerName: GENERAL_TEXT.reason,
            flex: 1,
            sortable: true,
            valueGetter: (params) =>
                `${getReasonLabel(params.row.reason) || '-'}`,
        },
        {
            field: 'reportCreatedBy',
            headerName: GENERAL_TEXT.reportAuthor,
            flex: 1,
            sortable: true,
            valueGetter: (params) =>
                `${params.row.createdBy.username || '-'}`,
        },
        {
            field: 'reportCreatedAt',
            headerName: GENERAL_TEXT.reportDate,
            flex: 1,
            sortable: true,
            valueGetter: (params) =>
                `${formatDateString(params.row.createdAt) || '-'}`,
        },
        {
            field: 'message',
            headerName: GENERAL_TEXT.ratingAndMessage,
            flex: 1,
            valueGetter: (params) =>
                `${params.row.review.rating}: ${params.row.review.message || `(${GENERAL_TEXT.noComment})`}`,
        },
        {
            field: 'reviewCreatedAt',
            headerName: GENERAL_TEXT.reviewDate,
            flex: 1,
            sortable: true,
            valueGetter: (params) =>
                `${formatDateString(params.row.review.updatedAt || params.row.review.createdAt)}`,
        },
        {
            field: 'reviewCreatedBy',
            headerName: GENERAL_TEXT.reviewAuthor,
            flex: 1,
            sortable: true,
            valueGetter: (params) =>
                `${params.row.review.createdBy.username || '-'}`,
        }
    ];

    const getRowSpacing = useCallback((params: GridRowSpacingParams) => {
        return {
            top: params.isFirstVisible ? 0 : 3,
            bottom: params.isLastVisible ? 0 : 3,
        };
    }, []);

    return (
        <DataGrid
            getRowId={() => Math.random()}
            onRowClick={(params) => (handleSetSelectReport(params.row))}
            rows={data}
            columns={columns}
            disableColumnMenu
            getRowSpacing={getRowSpacing}
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
            sx={{height: "80vh"}}
        />
    );
}

DataGridReportsComponent.propTypes = {
    data: PropTypes.array
}
