import {createContext, useEffect, useState} from "react";

import {useLazyQuery} from "@apollo/client";
import {Routes, Route, useNavigate, useLocation} from 'react-router-dom';
import Cookies from 'js-cookie';

import LoginPage from "./pages/LoginPage";
import ListOfRecipesPage from "./pages/ListOfRecipesPage";
import RecipePage from "./pages/RecipePage";
import HomePage from "./pages/HomePage";
import MarketingPage from "./pages/MarketingPage";
import EditRecipePage from "./pages/EditRecipePage";
import ListOfProductsPage from "./pages/ListOfProductsPage";
import EditProductPage from "./pages/EditProductPage";
import ProductPage from "./pages/ProductPage";
import NotFoundPage from "./pages/NotFoundPage";
import ListOfTags from "./pages/ListOfTagsPage";
import EditTagPage from "./pages/EditTagPage";
import LegalPage from "./pages/LegalPage";
import NotificationsPage from "./pages/NotificationsPage";
import ListOfReportsPage from "./pages/ListOfReportsPage";

import {checkRoleAccount} from "./utils/checkRoleAccount";
import {IS_TOKEN_VALID} from "./gql/securityQueries";
import {API_GATEWAY_API, SECURITY_API} from "./gql/clients";
import {MODERATION_STATUS} from "./constants/moderationStatus";
import {GET_PROFILE_INFORMATION} from "./gql/apiGatewayQueries";

export const BackOfficeContext = createContext();

export default function AppRouting() {
    const navigate = useNavigate()
    const location = useLocation().pathname

    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [loadingOK, setLoadingOK] = useState(false)

    const [userID, setUserID] = useState(0)
    const [userRoles, setUserRoles] = useState({
        rolePro: false,
        roleAdmin: false
    })

    const [isProAccount, setIsProAccount] = useState(false)
    const [isAdminAccount, setIsAdminAccount] = useState(false)

    const [showRecipeWithStatus, setShowRecipeWithStatus] = useState({
        [MODERATION_STATUS.pending]: true,
        [MODERATION_STATUS.shelved]: false,
        [MODERATION_STATUS.valid]: false,
        [MODERATION_STATUS.archived]: false
    })

    const [isTokenIsValid] = useLazyQuery(IS_TOKEN_VALID, {
        client: SECURITY_API,
        onCompleted: (data) => {
            if (!data.isTokenValid) {
                handleSignOut()
            } else {
                authenticatedAndNavigateUser(userRoles)
            }
            setLoadingOK(true)
        },
        onError: () => {
            handleSignOut(true)
            setLoadingOK(true)
        }
    });

    const [getProfileInformation] = useLazyQuery(GET_PROFILE_INFORMATION, {
        client: API_GATEWAY_API,
        onCompleted: (data) => {
            Cookies.set('profilePicture', data.getUser.profilePicture.publicUrl);
            Cookies.set('username', data.getUser.username);
        },
    });

    useEffect(() => {
        void checkSessionValidity();
    }, []);

    useEffect(() => {
        setIsProAccount(userRoles.rolePro)
        setIsAdminAccount(userRoles.roleAdmin)
    }, [userRoles]);

    const authenticatedAndNavigateUser = async (roles) => {
        setIsAuthenticated(true)

        const isReportRedirect = await Cookies.get('isReportRedirect') === "true" || false;

        if (roles.rolePro) {
            navigate('/marketing', {replace: true});
        }
        if (roles.roleAdmin && !isReportRedirect) {
            navigate('/moderation', {replace: true});
        }
    };

    const handleSignIn = async (data, rolesLogin) => {
        const {email, token, roles, id} = data;

        const firstChar = email.charAt(0).toUpperCase();

        Cookies.set('firstChar', firstChar);
        Cookies.set('session', token, {expires: 2 / 24});
        Cookies.set('token', token);
        Cookies.set('roles', roles);
        Cookies.set('userID', id);

        setUserID(id);
        setUserRoles(rolesLogin);

        await getProfileInformation({
            variables: {
                getUserId: id
            }
        });

        authenticatedAndNavigateUser(rolesLogin);
    };

    const handleSignOut = (setLoadingOKToTrue) => {
        Cookies.remove('session', 'token', 'firstChar', 'roles', 'userID', 'username', 'profilePicture');

        setUserRoles({
            rolePro: false,
            roleAdmin: false
        })

        const previouslyLoggedIn = isAuthenticated

        setIsAuthenticated(false)

        const recipePattern = /^\/recipe\/.*/;
        const userPattern = /^\/user\/.*/;
        const isLegalPage = location === "/gcu" || location === "/privacy-policy" || location === "/legal-notice" || location === "/delete-my-account"

        if (previouslyLoggedIn || (!previouslyLoggedIn && !isLegalPage && !recipePattern.test(location) && !userPattern.test(location))) {
            navigate('/');
        }

        if (setLoadingOKToTrue) setLoadingOK(true)
    };

    const checkSessionValidity = async () => {
        const token = Cookies.get('session');
        const type = "TYPE_AUTHENTICATION"

        const roles = Cookies.get('roles');
        const userID = Cookies.get('userID');

        setUserID(userID)
        setUserRoles(checkRoleAccount(roles))

        if (token) {
            await isTokenIsValid({
                variables: {
                    value: token,
                    type: type,
                }
            })
        } else {
            handleSignOut(true)
        }
    };

    if (loadingOK) {
        return (
            <BackOfficeContext.Provider value={{
                handleSignOut,
                handleSignIn,
                showRecipeWithStatus,
                setShowRecipeWithStatus,
                userRoles,
                userID
            }}>
                <Routes>
                    {!isAuthenticated ? (
                        <>
                            <Route path="/" element={<HomePage/>}/>
                            <Route path="/user/:userId" element={<HomePage/>}/>
                            <Route path="/recipe/:recipeId" element={<HomePage/>}/>

                            <Route path="/gcu" element={<LegalPage/>}/>
                            <Route path="/privacy-policy" element={<LegalPage/>}/>
                            <Route path="/legal-notice" element={<LegalPage/>}/>
                            <Route path="/delete-my-account" element={<LegalPage/>}/>

                            <Route
                                exact
                                path="/login"
                                element={<LoginPage/>}
                            />
                            <Route path="/*" element={<NotFoundPage redirectTo="/"/>}/>
                        </>
                    ) : (
                        <>
                            <Route
                                exact
                                path="/recipe/:recipeId"
                                element={<RecipePage isProAccount={isProAccount}/>}
                            />
                            <Route
                                exact
                                path={isProAccount ? "/ourRecipes" : "/moderation"}
                                element={<ListOfRecipesPage isProAccount={isProAccount}/>}
                            />
                            <Route
                                exact
                                path="/editRecipe/:recipeId"
                                element={<EditRecipePage isProAccount={isProAccount}/>}
                            />
                            <Route
                                path="/*"
                                element={<NotFoundPage redirectTo={isProAccount ? "/marketing" : "/moderation"}/>}
                            />
                            {isAdminAccount &&
                                <>
                                    <Route
                                        path="/tags"
                                        element={<ListOfTags/>}
                                    />
                                    <Route
                                        path="/editTag/:tagId"
                                        element={<EditTagPage/>}
                                    />
                                    <Route
                                        path="/notifications"
                                        element={<NotificationsPage/>}
                                    />
                                    <Route
                                        path="/reports"
                                        element={<ListOfReportsPage/>}
                                    />
                                </>
                            }
                            {isProAccount && (
                                <>
                                    <Route
                                        exact
                                        path="/marketing"
                                        element={<MarketingPage/>}
                                    />
                                    <Route
                                        exact
                                        path="/ourProducts"
                                        element={<ListOfProductsPage/>}
                                    />
                                    <Route
                                        exact
                                        path="/product/:productId"
                                        element={<ProductPage/>}
                                    />
                                    <Route
                                        exact
                                        path="/editProduct/:productId"
                                        element={<EditProductPage/>}
                                    />
                                </>
                            )}
                        </>
                    )}
                </Routes>
            </BackOfficeContext.Provider>
        );
    }
}

