import PropTypes from "prop-types";

import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useMutation} from "@apollo/client";
import {Menu, MenuItem,} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import ModalComponent from "./ModalComponent";
import ButtonComponent from "./ButtonComponent";

import {GENERAL_TEXT, MODAL_TEXT} from "../constants/text";
import {MODERATION_STATUS} from "../constants/moderationStatus";
import {RECIPE_API} from "../gql/clients";
import {
    ARCHIVE_RECIPE,
    REQUEST_NEW_MODERATION,
    SHELVE_RECIPE,
    UPDATE_RECIPE_STEPS,
    VALIDATE_RECIPE
} from "../gql/recipeQueries";

export default function ButtonModerationComponent(props) {
    const {recipe, recipeId, handleError} = props

    const navigate = useNavigate()
    const moderationStatus = recipe.status

    const [anchorEl, setAnchorEl] = useState(null);
    const [moderationReason, setModerationReason] = useState("")
    const [openModal, setOpenModal] = useState(false)
    const [textModal, setTextModal] = useState("")

    const [updateRecipeSteps] = useMutation(UPDATE_RECIPE_STEPS, {
        client: RECIPE_API,
        variables: {
            "input": {
                "id": recipeId,
                "steps": recipe.steps
            }
        },
        onCompleted: () => (editStatusModeration()),
        onError: (error) => (handleError(error))
    });

    const [validateRecipe] = useMutation(VALIDATE_RECIPE, {
        client: RECIPE_API,
        variables: {
            "input": {
                "id": recipeId,
            }
        },
        onCompleted: () => (navigate(`/moderation`)),
        onError: (error) => (handleError(error))
    });

    const [shelveRecipe] = useMutation(SHELVE_RECIPE, {
        client: RECIPE_API,
        variables: {
            "input": {
                "id": recipeId,
                "moderationReason": moderationReason
            }
        },
        onCompleted: () => (navigate(`/moderation`)),
        onError: (error) => (handleError(error))
    });

    const [archiveRecipe] = useMutation(ARCHIVE_RECIPE, {
        client: RECIPE_API,
        variables: {
            "input": {
                "id": recipeId,
                "moderationReason": moderationReason
            }
        },
        onCompleted: () => (navigate(`/moderation`)),
        onError: (error) => (handleError(error))
    });

    const [requestNewModeration] = useMutation(REQUEST_NEW_MODERATION, {
        client: RECIPE_API,
        variables: {
            "input": {
                "id": recipeId,
                "status": MODERATION_STATUS.pending
            }
        },
        onCompleted: () => (navigate(`/moderation`)),
        onError: (error) => (handleError(error))
    });

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleConfirm = async () => {
        await updateRecipeSteps()
        setOpenModal(false)
        setModerationReason('');
    };

    const editStatusModeration = async () => {
        // eslint-disable-next-line default-case
        switch (textModal) {
            case MODAL_TEXT.validated :
                await validateRecipe()
                break;
            case MODAL_TEXT.shelved :
                await shelveRecipe()
                break;
            case MODAL_TEXT.archived :
                await archiveRecipe()
                break;
            case MODAL_TEXT.requestNewModeration :
                await requestNewModeration()
                break;
        }
    };
    const handleCloseModal = () => {
        setOpenModal(false)
    };

    const handleChangeModerationReason = (event) => {
        setModerationReason(event.target.value);
    };

    const MenuDisplayManager = () => {
        if (moderationStatus === MODERATION_STATUS.pending) {
            return (
                <>
                    {recipe.public &&
                        <>
                            <MenuItem onClick={() => {
                                setTextModal(MODAL_TEXT.validated)
                                setOpenModal(true)
                            }}>
                                {GENERAL_TEXT.validateTheRecipe}
                            </MenuItem>
                            <MenuItem onClick={() => {
                                setTextModal(MODAL_TEXT.shelved)
                                setOpenModal(true)
                            }}>
                                {GENERAL_TEXT.requestChange}
                            </MenuItem>
                        </>
                    }
                    <MenuItem onClick={() => {
                        setTextModal(MODAL_TEXT.archived)
                        setOpenModal(true)
                    }}>
                        {GENERAL_TEXT.archivedRecipe}
                    </MenuItem>
                </>
            )
        } else {
            return (
                <MenuItem onClick={() => {
                    setTextModal(MODAL_TEXT.requestNewModeration)
                    setOpenModal(true)
                }}>
                    {GENERAL_TEXT.requestNewModeration}
                </MenuItem>)
        }
    }

    return (
        <div>
            <ButtonComponent
                onClick={handleClick}
                label={GENERAL_TEXT.moderate}
                endIcon={<KeyboardArrowDownIcon/>}
            />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                getContentAnchorEl={null}
            >
                <MenuDisplayManager/>
            </Menu>
            <ModalComponent
                open={openModal}
                onClose={handleCloseModal}
                onConfirm={handleConfirm}
                titleModal={textModal}
                moderationReason={moderationReason}
                onChangeModerationReason={handleChangeModerationReason}
            />
        </div>
    );
}

ButtonModerationComponent.propTypes = {
    recipe: PropTypes.object,
    recipeId: PropTypes.string.isRequired,
    handleError: PropTypes.func.isRequired
}
