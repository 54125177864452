export function getMicroserviceUrl(microservice) {
    const fake = process.env.REACT_APP_FAKE_API === 'true';
    let url = process.env.REACT_APP_API_BASE_URL.replace(/{microservice}/, fake ? 'faker' : microservice);

    if (fake) {
        url += 'graphql';
    }

    return url;
}
