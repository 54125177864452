import PropTypes from "prop-types";
import React, {useEffect, useState} from 'react';

import {Grid, Typography} from "@mui/material";
import Modal from '@mui/material/Modal';
import Cookies from "js-cookie";

import ButtonComponent from "./ButtonComponent"

import {formatDateString} from "../utils/formatDateString";
import {COLORS} from "../constants/colors";
import {GENERAL_TEXT} from "../constants/text";
import {REPORT_REASON_ITEMS} from "../constants/reportReasons";

export default function ModalReportComponent(props) {
    const {
        open,
        onClose,
        selectedReport,
        processReport,
        deleteReviewLoading,
        deleteReportLoading
    } = props

    const [reasonLabel, setReasonLabel] = useState("")
    const [reasonMessage, setReasonMessage] = useState("")

    useEffect(() => {
        const lineItem = REPORT_REASON_ITEMS.find((el) => el.value === selectedReport.reason)
        setReasonMessage(lineItem.message)
        setReasonLabel(lineItem.label)
    }, [])

    const redirectToRecipe = async () => {
        await Cookies.set('isReportRedirect', "true", {expires: 0.1 / 24});
        window.open(`/recipe/${selectedReport.review.recipe.id}`, '_blank')
    }

    return (
        <Modal open={open} onClose={onClose}>
            <Grid
                container
                width="100vw"
                height="100vh"
                justifyContent="center"
                alignItems="center"
            >
                <Grid
                    container
                    width="75%"
                    justifyContent="center"
                    alignItems="center"
                    bgcolor={COLORS.white}
                    px={2}
                    py={4}
                    borderRadius={2}
                >
                    <Grid container justifyContent="center" alignItems="center" direction="column">
                        <Typography color={COLORS.grey} align="center" variant="body1" mb={1}>
                            {GENERAL_TEXT.processingReport} n°{selectedReport.id}
                        </Typography>
                        <Typography color={COLORS.grey} align="center" variant="body1" mb={1}>
                            {GENERAL_TEXT.recipe} n°{selectedReport.review.recipe.id}
                        </Typography>
                        <ButtonComponent
                            onClick={redirectToRecipe}
                            label={GENERAL_TEXT.seeRelevantRecipe}
                            variantText
                        />
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        alignItems="flex-start"
                        mt={2}
                        border={1}
                        p={2}
                        borderRadius={2}
                        borderColor={COLORS.orange}
                    >
                        <Typography
                            color={COLORS.grey}
                            align="center"
                            variant="body1"
                            mb={1}
                        >
                            {GENERAL_TEXT.reportAuthor}: {selectedReport.createdBy.username}
                        </Typography>
                        <Typography
                            color={COLORS.grey}
                            align="center"
                            variant="body1"
                            mb={1}
                        >
                            {GENERAL_TEXT.reportDate}: {formatDateString(selectedReport.createdAt)}
                        </Typography>
                        <Typography
                            color={COLORS.grey}
                            align="center"
                            variant="body1">
                            {GENERAL_TEXT.reason}: {reasonLabel} - {reasonMessage}
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        alignItems="flex-start"
                        mt={2}
                        border={1}
                        p={2}
                        borderRadius={2}
                        borderColor={COLORS.orange}
                    >
                        <Typography
                            color={COLORS.grey}
                            align="center"
                            variant="body1"
                            mb={1}
                        >
                            {GENERAL_TEXT.reviewAuthor}: {selectedReport.review.createdBy.username}
                        </Typography>
                        <Typography
                            color={COLORS.grey}
                            align="center"
                            variant="body1"
                            mb={1}
                        >
                            {GENERAL_TEXT.reviewDate}: {formatDateString(selectedReport.review.updatedAt || selectedReport.review.createdAt)}
                        </Typography>
                        <Typography
                            color={COLORS.grey}
                            align="center"
                            variant="body1"
                        >
                            {GENERAL_TEXT.ratingAndMessage}: {selectedReport.review.rating}/5
                            - {selectedReport.review.message || `(${GENERAL_TEXT.noComment})`}
                        </Typography>
                    </Grid>
                    <Grid container mt={4} alignItems="center" justifyContent="space-between">
                        <Grid item justifyContent="space-around" alignItems="center">
                            <ButtonComponent
                                onClick={onClose}
                                label={GENERAL_TEXT.back}
                                variantText
                            />
                        </Grid>
                        <Grid item justifyContent="space-around" alignItems="center">
                            <ButtonComponent
                                onClick={() => processReport(false)}
                                label={GENERAL_TEXT.ignoreAndDeleteReport}
                                loading={deleteReportLoading}
                            />
                        </Grid>
                        <Grid item justifyContent="space-around" alignItems="center">
                            <ButtonComponent
                                onClick={() => processReport(true)}
                                label={GENERAL_TEXT.confirmAndDeleteReview}
                                loading={deleteReportLoading || deleteReviewLoading}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    );
};

ModalReportComponent.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    selectedReport: PropTypes.object,
    processReport: PropTypes.object,
    deleteReviewLoading: PropTypes.bool,
    deleteReportLoading: PropTypes.bool
}
