import {useContext, useState} from "react";

import {useMutation} from "@apollo/client";
import {toast} from 'react-toastify';
import {Grid} from "@mui/material";
import 'react-toastify/dist/ReactToastify.css';

import TextFieldComponent from "../components/TextFieldComponent";
import ButtonComponent from "../components/ButtonComponent";
import ToastComponent from "../components/ToastComponent"

import {handleErrorMessageAndLogoutIfNecessary} from "../utils/handleErrorMessageAndLogoutIfNecessary";
import {checkRoleAccount} from "../utils/checkRoleAccount";
import {APPLICATION_NAME, GENERAL_TEXT, TEXT_ERROR} from "../constants/text";
import {LOGIN} from "../gql/securityQueries";
import {SECURITY_API} from "../gql/clients";

import {BackOfficeContext} from "../AppRouting";

import '../assets/css/pages/LoginPage.css';

export default function LoginPage() {
    const {handleSignIn, handleSignOut} = useContext(BackOfficeContext);

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const [tryLogin,{loading}] = useMutation(LOGIN, {
        client: SECURITY_API,
        onCompleted: (data) => {
            const roles = checkRoleAccount(data.login.roles)
            const connectionAllowed = roles.rolePro || roles.roleAdmin

            if (connectionAllowed) {
                handleSignIn(data.login, roles)
            } else {
                toast.error(TEXT_ERROR.textInsufficientRights)
            }
        },
        onError: (error) => toast.error(handleErrorMessageAndLogoutIfNecessary(error, handleSignOut)),
    });

    const onKeyDown = (event) => {
        if (event.key === "Enter") {
            onClick();
        }
    };

    const onChangeEmail = (event) => {
        setEmail(event.target.value)
    }

    const onChangePassword = (event) => {
        setPassword(event.target.value)
    }

    const onClick = async () => {
        if (email && password) {
            await tryLogin({
                variables: {
                    input: {
                        email: email,
                        password: password,
                    },
                },
            });
        } else {
            toast.error(TEXT_ERROR.validEmailPassword)
        }
    }

    return (
        <Grid container sx height="100vh" width="100vw" justifyContent="center" alignItems="center">
            <Grid container justifyContent="center" alignItems="center">
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item mb={5}>
                        <h1 className="login-page-title">{APPLICATION_NAME}</h1>
                    </Grid>
                    <Grid item mb={5}>
                        <h1 className="login-page-text">{GENERAL_TEXT.backOffice}</h1>
                    </Grid>
                    <Grid container direction="column" spacing={2} justifyContent="center" alignItems="center">
                        <Grid item width="25rem">
                            <TextFieldComponent
                                id="outlined-basic"
                                label={GENERAL_TEXT.email}
                                value={email}
                                onChange={onChangeEmail}
                                onKeyDown={onKeyDown}
                            />
                        </Grid>
                        <Grid item width="25rem">
                            <TextFieldComponent
                                id="outlined-adornment-password"
                                label={GENERAL_TEXT.password}
                                password
                                value={password}
                                onChange={onChangePassword}
                                onKeyDown={onKeyDown}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container width="30%" justifyContent="center" alignItems="center" mt={6}>
                    <ButtonComponent
                        label={GENERAL_TEXT.login}
                        onClick={onClick}
                        width="50%"
                        margin={40}
                        loading={loading}
                    />
                </Grid>
            </Grid>
            <ToastComponent/>
        </Grid>
    )
        ;
}
