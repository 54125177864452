import PropTypes from "prop-types";

import {COLORS} from "../constants/colors";

import {Grid, Typography} from "@mui/material";

export default function ModerationStatusComponent(props) {
    const {name, number, onClick, selected, color, opaqueColor, showNumber} = props

    const handleColor = () => {
        if (showNumber) {
            return selected ? COLORS.white : color
        } else {
            return color
        }
    }

    const handleBGColor = () => {
        if (showNumber) {
            return selected ? color : COLORS.white
        } else {
            return opaqueColor
        }
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            xs
            onClick={onClick}
            disabled={!showNumber}
            border={1}
            px={2}
            py={1}
            bgcolor={handleBGColor}
            color={handleColor}
            borderRadius={showNumber ? 2 : 8}
            borderColor={showNumber ? handleColor() : "transparent"}
            sx={{
                ...(showNumber && {
                    cursor: "pointer",
                    '&:hover': {
                        opacity: 0.5,
                    },
                }),
                height: "100%",
            }}
        >
            <Grid item mr={showNumber && 1}>
                <Typography variant="body2">{name}</Typography>
            </Grid>
            {showNumber &&
                <Grid
                    item
                    px={0.5}
                    py={0.2}
                    bgcolor={selected ? COLORS.opaqueWhite60 : opaqueColor}
                    borderRadius={8}
                >
                    <Typography variant="body2">{number}</Typography>
                </Grid>
            }
        </Grid>
    );
}

ModerationStatusComponent.propTypes = {
    name: PropTypes.string.isRequired,
    number: PropTypes.number,
    onClick: PropTypes.func,
    color: PropTypes.string.isRequired,
    opaqueColor: PropTypes.string,
    selected: PropTypes.bool.isRequired,
    showNumber: PropTypes.bool,
}
