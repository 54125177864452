import React from "react";
import {useNavigate} from "react-router-dom";
import {Grid, Typography} from "@mui/material";

import {TEXT_ERROR} from "../constants/text";

import waves from "../assets/img/waves.png";

export default function NotFoundPage(props) {
    const {redirectTo} = props

    const navigate = useNavigate()

    return (
        <>
            <Grid
                container
                item
                xs
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{
                    position: "absolute",
                    zIndex: 100,
                    width: "100vw",
                    height: "100vh",
                    cursor: "pointer",
                }}
                spacing={2}
                onClick={() => navigate(redirectTo, {replace: true})}
            >
                <Typography variant="h3" color="white" mb={2}>{TEXT_ERROR.error404}</Typography>
                <Typography variant="h4" color="white">{TEXT_ERROR.clickToComeBack}</Typography>
            </Grid>
            <Grid
                container
                item
                xs
                justifyContent="center"
                alignItems="center"
                sx={{
                    position: "absolute",
                    zIndex: -1,
                    width: "100vw",
                    height: "100vh",
                }}
            >
                <img
                    src={waves}
                    style={{
                        maxHeight: "60vh",
                        width: "100vw"
                    }}
                    alt={"waves"}
                />
            </Grid>
        </>
    );
};

