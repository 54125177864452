import PropTypes from "prop-types";

import { LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import 'dayjs/locale/fr'
import {TimeField} from "@mui/x-date-pickers/TimeField";

dayjs.locale('fr')

export default function TimePickerComponent(props) {
    const {label, value, onChange } = props;

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimeField
                label={label}
                value={value}
                onChange={onChange}
                format="HH:mm"
                size="small"
                fullWidth
            />
        </LocalizationProvider>
    );
}

TimePickerComponent.propTypes = {
    value: PropTypes.any,
    label : PropTypes.string,
    onChange: PropTypes.func,
}
