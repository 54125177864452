import {
    ApolloClient,
    ApolloLink,
    HttpLink,
    InMemoryCache,
    from,
    split,
} from "@apollo/client";

import Cookies from 'js-cookie';
import {createUploadLink} from 'apollo-upload-client';

import {getMicroserviceUrl} from "../utils/api";

const defaultOptions = {
    watchQuery: {
        fetchPolicy: "no-cache",
    },
    query: {
        fetchPolicy: "no-cache",
    },
    mutate: {
        fetchPolicy: "no-cache",
    },
}

const cacheOptions = {
    addTypename : false
}

const authLink = new ApolloLink(async (operation, forward) => {
    const token = Cookies.get('token');

    operation.setContext({
        headers: {
            authorization: `Bearer ${token}`,
        },
    });
    return forward(operation);
});


const httpLinkRecipes = new HttpLink({
    uri: `${getMicroserviceUrl("recipes")}`,
});

const httpLinkSecurity = new HttpLink({
    uri: `${getMicroserviceUrl("security")}`,
});

const httpLinkApiGateway = createUploadLink({
    uri: `${getMicroserviceUrl("api-gateway")}`,
});

const httpLinkDrive = createUploadLink({
    uri: `${getMicroserviceUrl("drive")}`,
});

const httpLinkNotificationCenter = createUploadLink({
    uri: `${getMicroserviceUrl("notification-center")}`,
});

export const RECIPE_API = new ApolloClient({
    link: from([authLink, httpLinkRecipes]),
    cache: new InMemoryCache(cacheOptions),
    defaultOptions: defaultOptions
});

export const API_GATEWAY_API = new ApolloClient({
    link: from([authLink, httpLinkApiGateway]),
    cache: new InMemoryCache(cacheOptions),
    defaultOptions: defaultOptions
});

export const DRIVE_API = new ApolloClient({
    link: from([authLink, httpLinkDrive]),
    cache: new InMemoryCache(cacheOptions),
    defaultOptions: defaultOptions
});

export const NOTIFICATION_CENTER_API = new ApolloClient({
    link: from([authLink, httpLinkNotificationCenter]),
    cache: new InMemoryCache(cacheOptions),
    defaultOptions: defaultOptions
});

// "ApolloLink.split" method below, allows to modify the API link according to a condition :
// the first line is the test,
// the second, the link in case of success of the test,
// the third, the link in case of failure.

const checkOperationName = (operation) => {
    return (operation.operationName === "UpdateUser"
        || operation.operationName === "DeleteUser"
        || operation.operationName === "GetUserSettings"
        || operation.operationName === "GetUsers"
    );
};

const linkWithHeader = from([authLink, httpLinkSecurity]);
const linkWithoutHeader = from([httpLinkSecurity]);

const splitLinkSecurityAPI = split(
    checkOperationName,
    linkWithHeader,
    linkWithoutHeader
);
export const SECURITY_API = new ApolloClient({
    link: splitLinkSecurityAPI,
    cache: new InMemoryCache(cacheOptions),
    defaultOptions: defaultOptions
});


