import PropTypes from "prop-types";

import {useCallback} from "react";

import {useNavigate} from 'react-router-dom';

import {DataGrid, frFR} from '@mui/x-data-grid';
import {GridRowSpacingParams} from "@mui/x-data-grid";
import {Avatar} from "@mui/material";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PendingIcon from '@mui/icons-material/Pending';
import EditIcon from '@mui/icons-material/Edit';
import InventoryIcon from '@mui/icons-material/Inventory';
import DoneIcon from '@mui/icons-material/Done';

import {convertDate} from "../utils/convertDate";
import {MODERATION_STATUS} from "../constants/moderationStatus";
import {COLORS} from "../constants/colors";
import {LIST_TEXT} from "../constants/text";

import '../assets/css/components/ListOfRecipesModerationComponent.css'

export default function DataGridRecipesComponent(props) {
    const {data, isProAccount} = props;

    const navigate = useNavigate()
    const fontSize = "medium"

    const columns = [
        {
            field: 'picture',
            headerName: '',
            flex: 1,
            sortable: false,
            renderCell: (params) => {
                return <Avatar
                    sx={{width: "100%", height: "100%"}}
                    variant="square"
                    src={params.row.node.images[0].publicUrl}
                />
            },
        },
        {
            field: 'id',
            headerName: 'ID',
            flex: 1.3,
            sortable: false,
            valueGetter: (params) =>
                `${params.row.node.id || '-'}`,
        },
        {
            field: 'name',
            headerName: LIST_TEXT.name,
            flex: 1.5,
            sortable: true,
            valueGetter: (params) =>
                `${params.row.node.name || '-'}`,
        },
        {
            field: 'editedBy',
            headerName: LIST_TEXT.createdBy,
            flex: 0.8,
            sortable: true,
            valueGetter: (params) =>
                `${params.row.node.createdBy ? params.row.node.createdBy.username : '-'}`,
        },
        {
            field: 'editedAt',
            headerName: LIST_TEXT.createdAt,
            flex: 0.8,
            sortable: true,
            valueGetter: (params) =>
                `${params.row.node.createdAt ? convertDate(params.row.node.createdAt): '-'}`,
        },
        {
            field: 'moderatedBy',
            headerName: LIST_TEXT.moderatedBy,
            flex: 0.8,
            sortable: true,
            valueGetter: (params) =>
                `${params.row.node.moderatedBy ? params.row.node.moderatedBy.username : '-'}`,
        },
        {
            field: 'moderatedAt',
            headerName: LIST_TEXT.moderatedAt,
            flex: 0.8,
            sortable: true,
            valueGetter: (params) =>
                `${params.row.node.moderatedAt ? convertDate(params.row.node.moderatedAt) : '-'}`,
        },
        {
            field: 'status',
            headerName: LIST_TEXT.status,
            sortable: false,
            flex: 0.5,
            renderCell: (params) => {
                // eslint-disable-next-line default-case
                switch (params.row.node.status) {
                    case MODERATION_STATUS.pending :
                        return <PendingIcon
                            fontSize={fontSize}
                            sx={{color: COLORS.purple}}
                        />
                    case MODERATION_STATUS.shelved :
                        return <EditIcon
                            fontSize={fontSize}
                            sx={{color: COLORS.blueOdeven}}
                        />
                    case MODERATION_STATUS.valid :
                        return <DoneIcon
                            fontSize={fontSize}
                            sx={{color: COLORS.green}}
                        />
                    case MODERATION_STATUS.archived :
                        return <InventoryIcon
                            fontSize={fontSize}
                            sx={{color: COLORS.grey}}
                        />
                }
            },
        },
        {
            field: 'public',
            headerName: LIST_TEXT.public,
            sortable: true,
            flex: 0.5,
            renderCell: (params) => {
                return params.row.node.public ?
                    <VisibilityIcon
                        sx={{color: COLORS.green}}
                        fontSize={fontSize}
                    />
                    : <VisibilityOffIcon
                        sx={{color: COLORS.red}}
                        fontSize={fontSize}
                    />
            },
        },
    ];

    const getRowClassName = (params) => {
        // eslint-disable-next-line default-case
        switch (params.row.node.status) {
            case MODERATION_STATUS.pending:
                return 'line-pending'
            case MODERATION_STATUS.shelved:
                return 'line-shelved'
            case MODERATION_STATUS.valid:
                return 'line-valid'
            case MODERATION_STATUS.archived:
                return 'line-archived'
        }
    };

    const getRowSpacing = useCallback((params: GridRowSpacingParams) => {
        return {
            top: params.isFirstVisible ? 0 : 3,
            bottom: params.isLastVisible ? 0 : 3,
        };
    }, []);

    const columnVisibility = {
        id : !isProAccount,
        editedBy: !isProAccount,
        editedAt : !isProAccount,
        moderatedBy: !isProAccount,
        moderatedAt: !isProAccount
    };

    return (
        <DataGrid
            getRowId={() => Math.random()}
            onRowClick={(params) => (navigate(`/recipe/${params.row.node.id}`))}
            rows={data}
            columns={columns}
            getRowClassName={getRowClassName}
            disableColumnMenu
            getRowSpacing={getRowSpacing}
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
            sx={{height : isProAccount ? "80vh" : "75vh"}}
            columnVisibilityModel={columnVisibility}
        />
    );
}

DataGridRecipesComponent.propTypes = {
    data: PropTypes.array,
    isProAccount: PropTypes.bool,
}
