import {TEXT_NAME_NOTIFICATION} from "./text";

export const ITEMS_NAMES_NOTIFICATIONS = [
    {
        label: TEXT_NAME_NOTIFICATION.promotional,
        value: "NOTIFICATIONS_PROMOTIONAL"
    },
    {
        label: TEXT_NAME_NOTIFICATION.update,
        value: "NOTIFICATIONS_UPDATE"
    }
]