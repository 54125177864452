import PropTypes from "prop-types";
import React from "react";

import {Page, Document, View, Image, Text, StyleSheet} from "@react-pdf/renderer";

import {formatDateString} from "../utils/formatDateString";
import {determineColorBasedOnPercentage} from "../utils/determineColorBasedOnPercentage";
import {GENERAL_TEXT} from "../constants/text";

export default function PDFContentComponent(props) {
    const {
        username,
        startDate,
        endDate,
        lineChart,
        globalStatsCounters,
        percentages,
        dataForTable
    } = props

    const dataToTable = () => {
        const sortedData = dataForTable.slice().sort((a, b) => a.node.name.localeCompare(b.node.name));

        return (
            <View style={[styles.section, {paddingHorizontal: 0}]} break>
                <Text style={[styles.sectionTitle, {marginVertical: 20}]}>
                    {GENERAL_TEXT.performanceRecipes}
                </Text>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <Text style={[styles.tableCell, {flex: 3}]}>Nom</Text>
                        <Text style={[styles.tableCell, {flex: 1}]}>Likes</Text>
                        <Text style={[styles.tableCell, {flex: 1}]}>Dislikes</Text>
                    </View>
                    {sortedData.map((el, index) => (
                        <View key={index} style={styles.tableRow}>
                            <Text style={[styles.tableCell, {flex: 3}]}>{el.node.name}</Text>
                            <Text style={[styles.tableCell, {flex: 1}]}>{el.node.likeCount}</Text>
                            <Text style={[styles.tableCell, {flex: 1}]}>{el.node.dislikeCount}</Text>
                        </View>
                    ))}
                </View>
            </View>
        );
    };

    return (
        <Document>
            <Page size="A4" style={styles.page} break={true}>
                <View style={styles.titleContainer}>
                    <Text style={styles.pageTitle}>
                        {GENERAL_TEXT.marketingReport} {username}
                    </Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.sectionTitle}>
                        KPIs {GENERAL_TEXT.of} {formatDateString(startDate)} {GENERAL_TEXT.at} {formatDateString(endDate)}
                    </Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.text}>
                        {globalStatsCounters.likes} {GENERAL_TEXT.likesOnRecipes}
                    </Text>
                    <Text style={[{color: determineColorBasedOnPercentage(percentages.likes)}, styles.text]}>
                        {percentages.likes > 0 && "+"}{percentages.likes}{GENERAL_TEXT.percentageLastPeriod}
                    </Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.text}>
                        {globalStatsCounters.dislikes} {GENERAL_TEXT.dislikesOnRecipes}
                    </Text>
                    <Text style={[{color: determineColorBasedOnPercentage(percentages.dislikes)}, styles.text]}>
                        {percentages.dislikes > 0 && "+"}{percentages.dislikes}{GENERAL_TEXT.percentageLastPeriod}
                    </Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.text}>
                        {globalStatsCounters.follows} {GENERAL_TEXT.newFollowers}
                    </Text>
                    <Text style={[{color: determineColorBasedOnPercentage(percentages.follows)}, styles.text]}>
                        {percentages.follows > 0 && "+"}{percentages.follows}{GENERAL_TEXT.percentageLastPeriod}
                    </Text>
                </View>
                <Image src={lineChart} style={styles.images}/>
                <View style={styles.section}>
                    {dataToTable()}
                </View>
            </Page>
        </Document>
    );
};

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        border: 1
    },
    titleContainer: {
        textAlign: 'center',
        margin: 10,
        padding: 10,
    },
    pageTitle: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    sectionTitle: {
        fontSize: 15,
        fontWeight: 'bold',
    },
    text: {
        fontSize: 12,
        marginVertical: 2,
    },
    section: {
        flexDirection: 'column',
        paddingHorizontal: 40,
        paddingVertical: 10
    },
    images: {
        marginTop: 20,
        backgroundColor: "white",
        paddingHorizontal: 10,
        width: "100%",
    },
    table: {
        display: 'table',
        width: '100%',
        borderStyle: 'solid',
        borderColor: '#000',
        marginBottom: 10,
        alignSelf: "center"
    },
    tableRow: {
        flexDirection: 'row',
    },
    tableCell: {
        fontSize: 12,
        padding: 5,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#000',
    },
});

PDFContentComponent.propTypes = {
    username: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    lineChart: PropTypes.string,
    globalStatsCounters: PropTypes.string,
    percentages: PropTypes.number,
};