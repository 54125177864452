import PropTypes from "prop-types";
import React, {useContext, useEffect, useState} from 'react';

import {useMutation} from "@apollo/client";
import {useDropzone} from 'react-dropzone';
import {CircularProgress, Grid, IconButton, Typography} from "@mui/material";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {toast} from "react-toastify";
import VideoCallIcon from '@mui/icons-material/VideoCall';

import ToastComponent from "./ToastComponent";

import {handleErrorMessageAndLogoutIfNecessary} from "../utils/handleErrorMessageAndLogoutIfNecessary";
import {COLORS} from "../constants/colors";
import {UPLOAD_FILE} from "../gql/driveQueries";
import {DRIVE_API} from "../gql/clients";
import {GENERAL_TEXT, TEXT_ERROR} from "../constants/text";

import {BackOfficeContext} from "../AppRouting";
import ReactPlayer from "react-player";

export const MediaPickerComponent = (props) => {
    const {selectedMedias, setSelectedMedias, setMedias, setLoadingDrive, numberMaxOfMedias, error, isVideo} = props

    const {handleSignOut} = useContext(BackOfficeContext);

    const [selectedFile, setSelectedFile] = useState(null)

    const [uploadFile, {loading: loadingDriveAPI}] = useMutation(UPLOAD_FILE, {
        client: DRIVE_API,
        onCompleted: (data) => {
            updateImage(data)
        },
        onError: (error) => toast.error(handleErrorMessageAndLogoutIfNecessary(error, handleSignOut)),
    });

    useEffect(() => {
        setLoadingDrive(loadingDriveAPI)
    }, [loadingDriveAPI])

    const sxPickerImage = {
        color: error ? "red" : COLORS.orange,
        borderBottomLeftRadius: 6,
        borderBottomRightRadius: 6,
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
        overflow: "hidden",
        height: "100%",
        width: "100%",
        border: error ? 2 : 1,
        cursor: "pointer"
    }

    const sxButton = {
        color: COLORS.orange,
        overflow: "hidden",
        border: 1,
        width: "100%",
        borderBottomLeftRadius: 6,
        borderBottomRightRadius: 6,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        position: "absolute",
        bottom: 0,
        backgroundColor: COLORS.opaqueWhite80,
        zIndex: 100
    }

    const onDrop = async (file) => {
        const allowedFormats = isVideo ? ['.mp4', '.avi', '.mov'] : ['.png', '.jpeg', '.jpg'];
        const maxSize = 2097152;

        if (!allowedFormats.some(format => file[0].name.endsWith(format))) {
            toast.error(TEXT_ERROR.imageFormat)
            return;
        }

        if (file[0].size > maxSize) {
            toast.error(TEXT_ERROR.mediaSize)
            return;
        }

        setSelectedFile(file)

        await uploadFile({
            variables: {
                input: {
                    file: file[0]
                },
            },
        })
    }

    const updateImage = (data) => {
        setSelectedMedias((prevSelectedImages) => [
            ...prevSelectedImages,
            ...selectedFile
        ]);

        setMedias((prevImages) => [
            ...prevImages,
            data.uploadFile.id
        ]);
    };

    const removeImage = (index) => {
        setSelectedMedias((prevSelectedImages) => {
            const updatedSelectedImages = [...prevSelectedImages];
            updatedSelectedImages.splice(index, 1);
            return updatedSelectedImages;
        });
        setMedias((prevImages) => {
            const updatedImages = [...prevImages];
            updatedImages.splice(index, 1);
            return updatedImages;
        });
    };

    const {getRootProps} = useDropzone({
        onDrop,
        multiple: false,
    });

    return (
        <Grid container direction="row" spacing={1} position="relative" px={1}>
            {selectedMedias.length > 0 && (
                <>
                    {selectedMedias.map((media, index) =>
                        (
                            <Grid
                                container
                                mr={4}
                                justifyContent="center"
                                alignItems="center"
                                sx={{
                                    width: "15rem"
                                }}
                                position="relative"
                                mb={4}
                            >
                                <Grid
                                    container
                                    sx={sxPickerImage}
                                    border={1}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    {isVideo ?
                                        <ReactPlayer
                                            url={media.publicUrl || URL.createObjectURL(media)}
                                            playing
                                            loop
                                            muted
                                        />
                                        :
                                        <img
                                            src={media.publicUrl || URL.createObjectURL(media)}
                                            style={{width: '100%', height: '100%'}}
                                            alt={`image ${index + 1}`}
                                        />
                                    }

                                </Grid>
                                <IconButton onClick={() => removeImage(index)} sx={sxButton}>
                                    <DeleteForeverIcon sx={{color: COLORS.orange}}/>
                                </IconButton>
                            </Grid>
                        )
                    )}
                </>
            )}
            {
                selectedMedias.length < numberMaxOfMedias && (
                    <Grid
                        container
                        border={1}
                        sx={{
                            ...sxPickerImage,
                            height: "15rem",
                            width: "15rem",
                            borderBottomLeftRadius: 6,
                            borderBottomRightRadius: 6,
                        }}
                        {...getRootProps()}
                        justifyContent="center"
                        alignItems="center"
                        direction="column"
                        px={2}
                    >
                        {loadingDriveAPI ?
                            <CircularProgress sx={{color: COLORS.orange}}/>
                            :
                            <>
                                <Grid item>
                                    {isVideo ?
                                        <VideoCallIcon fontSize="large"/>
                                        :
                                        <AddPhotoAlternateIcon fontSize="large"/>
                                    }
                                </Grid>
                                <Grid item mt={1}>
                                    <Typography
                                        align="center">{isVideo ? GENERAL_TEXT.videoPicker : GENERAL_TEXT.imagePicker}</Typography>
                                </Grid>
                            </>
                        }
                    </Grid>
                )
            }
            {
                error && (
                    <Grid item position="absolute" bottom={-25} left={0}>
                        <Typography variant="body-2" color="red">
                            {GENERAL_TEXT.requiredField}
                        </Typography>
                    </Grid>
                )
            }
            <ToastComponent/>
        </Grid>
    );
};

MediaPickerComponent.propTypes = {
    selectedMedias: PropTypes.array.isRequired,
    setSelectedMedias: PropTypes.func.isRequired,
    setMedias: PropTypes.func.isRequired,
    numberMaxOfMedias : PropTypes.number.isRequired,
    isVideo: PropTypes.bool,
    error: PropTypes.bool,
    setLoadingDrive: PropTypes.func
}