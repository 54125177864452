import React, {useEffect, useState} from "react";

import {Grid, Typography} from "@mui/material";
import {Link, useLocation} from "react-router-dom";

import {CheckIfIsSmartphone} from "../utils/CheckIfIsSmartphone";
import {GENERAL_TEXT, TEXT_LEGAL} from "../constants/text";
import {COLORS} from "../constants/colors";

import headerLogo from "../assets/img/headerLogo.png";

export default function LegalPage() {
    const location = useLocation().pathname

    const [title, setTitle] = useState("")
    const [text, setText] = useState("")

    const isSmartphone = CheckIfIsSmartphone()

    useEffect(() => {
        // eslint-disable-next-line default-case
        switch (location) {
            case "/gcu" :
                setTitle(GENERAL_TEXT.cgu)
                setText(TEXT_LEGAL.textCGU)
                break;
            case "/privacy-policy" :
                setTitle(GENERAL_TEXT.privacyPolicy)
                setText(TEXT_LEGAL.textPrivacyPolicy)
                break;
            case "/legal-notice" :
                setTitle(GENERAL_TEXT.legalNotice)
                setText(TEXT_LEGAL.textLegalNotice)
                break;
            case "/delete-my-account" :
                setTitle(GENERAL_TEXT.deleteMyAccount)
                setText(TEXT_LEGAL.textDeleteMyAccount)
                break;
        }
    }, []);


    return (
        <>
            <Grid
                container
                item
                justifyContent="start"
                alignItems="center"
                px={2}
                py={1}
                sx={{
                    backgroundColor: COLORS.orange,
                    width: "100vw",
                }}
            >
                <Link to="/" style={{textDecoration: "none", color: COLORS.white}}>
                    <img src={headerLogo}
                         style={{width: "8rem"}}
                         alt={"headerLogo"}
                    />
                </Link>
            </Grid>
            <Grid
                container
                px={isSmartphone ? 5 : 10}
                py={5}
                direction="column"
            >
                <Typography
                    variant="h4"
                    style={{whiteSpace: 'pre-line'}}
                    mb={5}
                >
                    {title}
                </Typography>
                <Typography
                    variant="body1"
                    style={{whiteSpace: 'pre-line'}}
                >
                    {text}
                </Typography>
            </Grid>
        </>
    )
        ;
}
