import PropTypes from "prop-types";

import {DataGrid, frFR} from '@mui/x-data-grid';

import {LIST_TEXT} from "../constants/text";

import '../assets/css/components/ListOfRecipesModerationComponent.css'

export default function DataGridStatsComponent(props) {
    const {data} = props;

    const columns = [
        {
            field: 'name',
            headerName: LIST_TEXT.name,
            flex: 3,
            sortable: true,
            valueGetter: (params) =>
                `${params.row.node.name || '-'}`,
        },
        {
            field: 'likeCount',
            headerName: LIST_TEXT.likes,
            flex: 1,
            sortable: true,
            valueGetter: (params) =>
                parseInt(params.row.node.likeCount)
        },
        {
            field: 'dislikeCount',
            headerName: LIST_TEXT.dislikes,
            flex: 1,
            sortable: true,
            valueGetter: (params) =>
                parseInt(params.row.node.dislikeCount)
        },
    ];

    return (
        <DataGrid
            getRowId={() => Math.random()}
            rows={data}
            columns={columns}
            disableColumnMenu
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
            sx={{width: "100%", minHeight: "55vh"}}
            rowSelection={false}
        />
    );
}

DataGridStatsComponent.propTypes = {
    data: PropTypes.array,
}
