import PropTypes from "prop-types";

import Icon from "@mdi/react";
import {Grid, Typography} from "@mui/material";
import {
    mdiBlender,
    mdiChefHat,
    mdiCurrencyEur,
    mdiSleep,
    mdiStove,
    mdiAccountGroup,
    mdiIdentifier,
    mdiTagMultiple
} from "@mdi/js";

import {COLORS} from "../constants/colors";
import {GENERAL_TEXT} from "../constants/text";

export default function PresentationRecipeWithIconComponent(props) {
    const {recipe, isProAccount} = props

    const sizeIcon = 0.7;
    const colorIcon = COLORS.orange;

    const renderRecipeInfo = (path, text) => {
        const isTag = path === mdiTagMultiple

        return (
            <Grid item>
                <Grid
                    container
                    bgcolor={isTag ? COLORS.white : COLORS.bgOrangeColor}
                    border={isTag ? 1 : 0}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    title={GENERAL_TEXT.recipeId}
                    borderRadius={8}
                    color={COLORS.orange}
                    px={1}
                    py={0.5}
                >
                    <Icon path={path} size={sizeIcon} color={colorIcon}/>
                    <Typography variant="body2" ml={0.5}>{text}</Typography>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container xs justifyContent="flex-end" spacing={1} mt={1} mr={-1}>
            {recipe.tags.map((tag) => renderRecipeInfo(mdiTagMultiple, tag.name))}
            {!isProAccount && renderRecipeInfo(mdiIdentifier, recipe.id)}
            {renderRecipeInfo(mdiAccountGroup, recipe.forPeople)}
            {renderRecipeInfo(mdiChefHat, recipe.difficulty)}
            {renderRecipeInfo(mdiCurrencyEur, recipe.cost)}
            {renderRecipeInfo(mdiBlender, recipe.preparationTime || "0:0")}
            {renderRecipeInfo(mdiSleep, recipe.restTime || "0:0")}
            {renderRecipeInfo(mdiStove, recipe.cookingTime || "0:0")}
        </Grid>
    );
}
;

PresentationRecipeWithIconComponent.propTypes = {
    recipe: PropTypes.object.isRequired,
    isProAccount: PropTypes.bool,
};
