import {TEXT_TAGS} from "./text";

export const ITEMS_TAGS_TYPES = [
    {label: TEXT_TAGS.categories, value: "TYPE_GENERAL_CATEGORY"},
    {label: TEXT_TAGS.region, value: "TYPE_REGION"},
    {label: TEXT_TAGS.diet, value: "TYPE_DIET"},
    {label: TEXT_TAGS.ingredient, value: "TYPE_MAIN_INGREDIENT"},
    {label: TEXT_TAGS.event, value: "TYPE_EVENT"},
]

export const getTagTypeLabel = (value) => {
    const matchingItem = ITEMS_TAGS_TYPES.find((el) => value === el.value);
    return  matchingItem.label
};