import PropTypes from "prop-types";
import {useCallback} from "react";

import {DataGrid, frFR, GridRowSpacingParams} from '@mui/x-data-grid';
import {useNavigate} from "react-router-dom";
import {Checkbox} from "@mui/material";

import {GENERAL_TEXT, LIST_TEXT} from "../constants/text";
import {getTagTypeLabel} from "../constants/tagsTypes";

export default function DataGridTagsComponent(props) {
    const {data} = props;

    const navigate = useNavigate()

    const columns = [
        {
            field: 'type',
            headerName: GENERAL_TEXT.type,
            flex: 4,
            sortable: true,
            renderCell: (params) => {
                return (
                    <div>
                        {getTagTypeLabel(params.row.type)}
                    </div>
                );
            }
        },
        {
            field: 'name',
            headerName: LIST_TEXT.name,
            flex: 3,
            sortable: true,
            valueGetter: (params) =>
                `${params.row.name || '-'}`,
        },
        {
            field: 'icon',
            headerName: GENERAL_TEXT.icon,
            flex: 6,
            sortable: false,
            valueGetter: (params) =>
                params.row.icon
        },
        {
            field: 'color',
            headerName: GENERAL_TEXT.color,
            flex: 3,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div style={{color: params.row.color}}>
                        {params.row.color}
                    </div>
                );
            },
        },
        {
            field: 'priority',
            headerName: GENERAL_TEXT.priority,
            flex: 3,
            sortable: true,
            valueGetter: (params) =>
                params.row.priority
        },
        {
            field: 'highlight',
            headerName: GENERAL_TEXT.highlight,
            flex: 3,
            sortable: false,
            renderCell: (params) => {
                return <Checkbox checked={params.row.highlight} disabled style={{ color: "black" }} />;
            },
        },
    ];

    const getRowSpacing = useCallback((params: GridRowSpacingParams) => {
        return {
            top: params.isFirstVisible ? 0 : 3,
            bottom: params.isLastVisible ? 0 : 3,
        };
    }, []);

    return (
        <DataGrid
            getRowId={() => Math.random()}
            rows={data}
            columns={columns}
            disableColumnMenu
            getRowSpacing={getRowSpacing}
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
            sx={{width: "100%", minHeight: "80vh"}}
            onRowClick={(params) => (navigate(`/editTag/${params.row.id}`))}
        />
    );
}

DataGridTagsComponent.propTypes = {
    data: PropTypes.array,
}
