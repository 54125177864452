import React, {useContext, useState} from "react";

import {useMutation, useQuery} from "@apollo/client";
import {Grid, Typography} from "@mui/material";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

import HeaderComponent from "../components/HeaderComponent";
import ToastComponent from "../components/ToastComponent";
import VerticalNavBarComponent from "../components/VerticalNavBarComponent";
import ButtonComponent from "../components/ButtonComponent";
import TextFieldComponent from "../components/TextFieldComponent";
import SelectComponent from "../components/SelectComponent";
import AutoCompleteComponent from "../components/AutoCompleteComponent";
import ModalComponent from "../components/ModalComponent";

import {handleErrorMessageAndLogoutIfNecessary} from "../utils/handleErrorMessageAndLogoutIfNecessary";
import {GET_RECIPES} from "../gql/recipeQueries";
import {NOTIFICATION_CENTER_API, RECIPE_API, SECURITY_API} from "../gql/clients";
import {GENERAL_TEXT, MODAL_TEXT} from "../constants/text";
import {COLORS} from "../constants/colors";
import {GET_USERS} from "../gql/securityQueries";
import {NOTIFY_ALL_USERS} from "../gql/notificationCenterQueries";
import {ITEMS_NAMES_NOTIFICATIONS} from "../constants/namesNotifications";

import {BackOfficeContext} from "../AppRouting";

export default function NotificationsPage() {
    const navigate = useNavigate();

    const {handleSignOut} = useContext(BackOfficeContext);

    const [title, setTitle] = useState("")
    const [body, setBody] = useState("")
    const [subtitle, setSubtitle] = useState("")

    const [id, setId] = useState("")
    const [name, setName] = useState("")
    const [screen, setScreen] = useState("")

    const [userSettingName, setUserSettingName] = useState("")
    const [users, setUsers] = useState([])
    const [recipes, setRecipes] = useState([])

    const [checkError, setCheckError] = useState(false);
    const disabledScreenSelection = userSettingName === "NOTIFICATIONS_UPDATE"

    const emptyType = {
        "label": GENERAL_TEXT.none, "value": ""
    }

    const screenSelectData = [
        {
            "label": GENERAL_TEXT.recipe,
            "value": "recipe"
        },
        {
            "label": GENERAL_TEXT.user,
            "value": "user"
        },
    ]

    const [formValidation, setFormValidation] = useState({
        title: false,
        type: false,
        body: false,
        id: false
    })

    const [modal, setModal] = useState({
        visible: false,
        moderationReason: '',
    })

    useQuery(GET_USERS, {
        client: SECURITY_API,
        onCompleted: (data) => updateData(data, 'getUsers', 'label', "username", setUsers),
        onError: (error) => toast.error(handleErrorMessageAndLogoutIfNecessary(error, handleSignOut)),
    });

    useQuery(GET_RECIPES, {
        client: RECIPE_API,
        onCompleted: (data) => updateData(data, 'getRecipes', 'label', "name", setRecipes),
        onError: (error) => toast.error(handleErrorMessageAndLogoutIfNecessary(error, handleSignOut)),
    });

    const [notifyAllUsers, {loading: loadingSendNotification}] = useMutation(NOTIFY_ALL_USERS, {
        client: NOTIFICATION_CENTER_API,
        onCompleted: () => {
            navigate('/moderation')
        },
        onError: (error) => toast.error(handleErrorMessageAndLogoutIfNecessary(error, handleSignOut)),
    });

    const updateData = (data, propertyToUpdate, labelProperty, valueProperty, setFunction) => {
        const isRecipes = propertyToUpdate === "getRecipes"
        const isUsers = propertyToUpdate === "getUsers"
        const newArray = data[propertyToUpdate]

        for (let i = 0; i < newArray.length; i++) {
            newArray[i][labelProperty] = newArray[i][valueProperty];
            delete newArray[i][valueProperty];
            if (isRecipes && (newArray[i].status !== 'VALID' || !newArray[i].public)) {
                delete newArray[i];
            }
            if (isUsers && !newArray[i].label) {
                delete newArray[i]
            }
        }

        setFunction(newArray)
    };

    const handleChangeInput = (event, setData, inputName) => {
        setData(event.target.value)

        if (inputName) {
            setFormValidation((prevFormValidationStep) => ({
                ...prevFormValidationStep,
                [inputName]: event.target.value.length > 1,
            }));
        }
    }

    const handleChangeAutoComplete = (event, newValue, inputName) => {
        if (newValue) {
            setId(newValue.id)
            setName(newValue.label)
        } else {
            setId("")
            setName("")
        }

        if (inputName) {
            setFormValidation((prevFormValidationStep) => ({
                ...prevFormValidationStep,
                [inputName]: newValue
            }));
        }
    }

    const formIsValid = () => {
        const {title, type, body, id} = formValidation;

        if (formValidation.screen) {
            return title && type && body && id;
        } else {
            return title && type && body;
        }
    }

    const onPressSendButton = () => {
        if (formIsValid()) {
            setModal({
                ...modal,
                visible: true,
            })
        } else {
            setCheckError(true)
        }
    };

    const sendNotifications = async () => {
        const variables = {
            input: {
                email: false,
                push: true,
                body: body,
                title: title,
                userSettingName: userSettingName,
                priority: "normal"
            }
        }
        if (screen && !disabledScreenSelection) {
            variables.input.data = {
                screen: screen,
                id: id
            }
        }
        if (subtitle) {
            variables.input.subtitle = subtitle
        }

        console.log(variables)

        await notifyAllUsers({
            variables: variables
        })
    }

    const handleCloseModal = () => {
        setModal({...modal, "visible": false})
    };

    const onAddEmoji = (emojiData, setData) => {
        setData(prevValue => prevValue + emojiData.emoji)
    };

    return (
        <>
            <HeaderComponent/>
            <Grid container xs direction="row" justifyContent="center" alignItems="flex-start">
                <Grid container alignItems="center" xs={2}>
                    <VerticalNavBarComponent/>
                </Grid>
                <Grid container direction="column" alignItems="center" px={2} xs={10} mt={10}>
                    <Grid container xs={12} py={2} direction="row" alignItems="center"
                          justifyContent="space-between">
                        <Typography ml={1} variant="body1" color={COLORS.orange}>
                            {GENERAL_TEXT.newNotification}
                        </Typography>
                        <Grid item mr={1}>
                            <ButtonComponent
                                loading={loadingSendNotification}
                                label={GENERAL_TEXT.send}
                                onClick={() => onPressSendButton()}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} direction="row" alignItems="center" spacing={1}>
                        <Grid item xs={8}>
                            <TextFieldComponent
                                id="title"
                                label={GENERAL_TEXT.title}
                                value={title}
                                emojiPicker
                                onAddEmoji={(emojiData) => onAddEmoji(emojiData, setTitle)}
                                onChange={(event) => handleChangeInput(event, setTitle, "title")}
                                maxLength={65}
                                error={checkError && !formValidation.title}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <SelectComponent
                                label={GENERAL_TEXT.type}
                                value={userSettingName}
                                onChange={(event) => handleChangeInput(event, setUserSettingName, "type")}
                                data={ITEMS_NAMES_NOTIFICATIONS}
                                error={checkError && !formValidation.type}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} direction="row" alignItems="center" mt={4} spacing={1}>
                        <Grid item xs={4}>
                            <SelectComponent
                                disabled={disabledScreenSelection}
                                label={GENERAL_TEXT.redirect}
                                value={disabledScreenSelection ? "" : screen}
                                onChange={(event) => handleChangeInput(event, setScreen, "screen")}
                                data={[emptyType, ...screenSelectData]}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <AutoCompleteComponent
                                value={!screen || disabledScreenSelection ? "" : name}
                                label={GENERAL_TEXT.name}
                                disabled={!screen || disabledScreenSelection}
                                onChange={(event, newValue) => handleChangeAutoComplete(event, newValue, "id")}
                                options={screen === "recipe" ? recipes : users}
                                error={screen ? (checkError && !formValidation.id) : false}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} mt={4} spacing={1}>
                        <Grid item xs>
                            <TextFieldComponent
                                id="subtitle"
                                label={GENERAL_TEXT.subtitle}
                                value={subtitle}
                                onChange={(event) => handleChangeInput(event, setSubtitle)}
                                maxLength={255}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} mt={4} spacing={1}>
                        <Grid item xs>
                            <TextFieldComponent
                                id="body"
                                multiline
                                rows={5}
                                value={body}
                                onChange={(event) => handleChangeInput(event, setBody, "body")}
                                label={GENERAL_TEXT.message}
                                emojiPicker
                                onAddEmoji={(emojiData) => onAddEmoji(emojiData, setBody)}
                                maxLength={240}
                                error={checkError && !formValidation.body}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <ToastComponent/>
                <ModalComponent
                    open={modal.visible}
                    onClose={handleCloseModal}
                    onConfirm={async () => {
                        await sendNotifications()
                        setModal({...modal, "visible": false})
                    }}
                    titleModal={MODAL_TEXT.confirmNotification}
                />
            </Grid>
        </>
    );
}
