import PropTypes from "prop-types";
import React from 'react';

import {Grid, Typography} from "@mui/material";
import Modal from '@mui/material/Modal';

import TextFieldComponent from "./TextFieldComponent";
import ButtonComponent from "./ButtonComponent"

import {COLORS} from "../constants/colors";
import {GENERAL_TEXT, MODAL_TEXT} from "../constants/text";

export default function ModalComponent(props) {
    const {
        open,
        onClose,
        onConfirm,
        titleModal,
        moderationReason,
        onChangeModerationReason
    } = props

    const needReason = titleModal === MODAL_TEXT.modifyStep || titleModal === MODAL_TEXT.shelved || titleModal === MODAL_TEXT.archived
    const isConfirmDisabled = needReason && moderationReason.length < 2;

    return (
        <Modal open={open} onClose={onClose}>
            <Grid
                container
                width="100vw"
                height="100vh"
                justifyContent="center"
                alignItems="center"
            >
                <Grid
                    container
                    width="50%"
                    justifyContent="center"
                    alignItems="center"
                    bgcolor={COLORS.white}
                    px={2}
                    py={4}
                    borderRadius={2}
                >
                    <Typography color={COLORS.orange} align="center" variant="body1">{titleModal}</Typography>
                    {needReason &&
                        <Grid item width="100%" mt={2}>
                            <TextFieldComponent
                                id="outlined-basic"
                                label={titleModal === MODAL_TEXT.modifyStep ? GENERAL_TEXT.description : GENERAL_TEXT.moderationReason}
                                value={moderationReason}
                                onChange={onChangeModerationReason}
                                width="100%"
                                rows={10}
                                multiline
                            />
                        </Grid>
                    }
                    <Grid container justifyContent="center" alignItems="center" mt={2} spacing={4}>
                        <Grid item justifyContent="space-around" alignItems="center">
                            <ButtonComponent
                                onClick={onClose}
                                label={GENERAL_TEXT.back}
                            />
                        </Grid>
                        <Grid item justifyContent="space-around" alignItems="center">
                            <ButtonComponent
                                onClick={onConfirm}
                                disabled={isConfirmDisabled}
                                label={GENERAL_TEXT.confirm}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    );
};

ModalComponent.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    titleModal: PropTypes.string,
    moderationReason: PropTypes.string,
    onChangeModerationReason: PropTypes.func,
}
