/** Return color base on percentage
 *
 * @param {number} percentage
 * @return {string}
 */
export const determineColorBasedOnPercentage = (percentage) => {
    if (percentage > 2) {
        return "green";
    } else if (percentage < -2) {
        return "red";
    } else {
        return "orange";
    }
};