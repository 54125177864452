import { gql } from "@apollo/client";
export const ARCHIVE_RECIPE = gql`
mutation ArchiveRecipe($input: ArchiveRecipeInput!) {
  archiveRecipe(input: $input) {
    status
  }
}
`;

export const SHELVE_RECIPE = gql`
mutation ShelveRecipe($input: ShelveRecipeInput!) {
  shelveRecipe(input: $input) {
    status
  }
}
`;

export const VALIDATE_RECIPE = gql`
mutation ValidateRecipe($input: ValidateRecipeInput!) {
  validateRecipe(input: $input) {
    status
  }
}
`;

export const REQUEST_NEW_MODERATION = gql`
mutation UpdateRecipe($input: UpdateRecipeInput!) {
  updateRecipe(input: $input) {
    status
  }
}
`;

export const UPDATE_RECIPE_STEPS = gql`
mutation Mutation($input: UpdateRecipeInput!) {
    updateRecipe(input: $input) {
        id
    }
}
`;

export const GET_RECIPES_STATS = gql`
query SearchRecipes($mine: Boolean) {
  searchRecipes(mine: $mine) {
   edges {
      node {
    name
    likeCount
    dislikeCount
    }
    }
  }
}
`;

export const CREATE_RECIPE = gql`
  mutation CreateRecipe($input: CreateRecipeInput!) {
    createRecipe(input: $input) {
      id
    }
  }
`;

export const UPDATE_RECIPE = gql`
mutation UpdateRecipe($input: UpdateRecipeInput!) {
  updateRecipe(input: $input) {
    id
  }
}
`;

export const DELETE_RECIPE = gql`
mutation DeleteRecipe($deleteRecipeId: ID!) {
  deleteRecipe(id: $deleteRecipeId)
}
`
;export const CREATE_PRODUCT = gql`
mutation CreateProduct($input: CreateProductInput!) {
    createProduct(input: $input) {
        id
    }
}
`;

export const UPDATE_PRODUCT = gql`
mutation UpdateProduct($input: UpdateProductInput!) {
  updateProduct(input: $input) {
    id
  }
}
`;

export const DELETE_PRODUCT = gql`
mutation Mutation($deleteProductId: ID!) {
  deleteProduct(id: $deleteProductId)
}
`;

export const GET_TAGS = gql`
query GetTags {
  getTags {
    color
    highlight
    icon
    id
    name
    type
    priority
  }
}
`;

export const GET_TAG = gql`
query GetTag($getTagId: ID!) {
  getTag(id: $getTagId) {
    color
    highlight
    icon
    id
    name
    priority
    type
  }
}
`;


export const CREATE_TAG = gql`
mutation CreateTag($input: CreateTagInput!) {
  createTag(input: $input) {
    id
  }
}
`;


export const UPDATE_TAG = gql`
mutation UpdateTag($input: UpdateTagInput!) {
  updateTag(input: $input) {
    id
  }
}
`;

export const DELETE_TAG = gql`
mutation DeleteTag($deleteTagId: ID!) {
  deleteTag(id: $deleteTagId)
}
`;

export const GET_RECIPES = gql`
query GetRecipes{
  getRecipes {
    id
    name
    status
    public
  }
}
`;

export const DELETE_REVIEW = gql`
mutation Mutation($deleteReviewId: ID!) {
  deleteReview(id: $deleteReviewId)
}
`;

export const DELETE_REPORT = gql`
mutation Mutation($deleteReportId: ID!) {
  deleteReport(id: $deleteReportId)
}
`;


