/** Determine if the user must be logout or the message, based on the error
 *
 * @param {Object} error
 * @param {function} handleSignOut
 * @return {string}
 *
 */
import {TEXT_ERROR} from "../constants/text";

const ACCESS_DENIED_TO_THIS_FIELD = "Access denied to this field.";
const TIMEOUT_EXCEEDED = "Timeout exceeded";

const hasErrorMessage = (error, message) => {
    return Object.values(error).indexOf(message) !== -1;
}

function hasErrorCode(error) {
    return error.graphQLErrors.length !== 0;
}

function getErrorCode(error) {
    return error.graphQLErrors[0].extensions?.code;
}

const tokenIsNotValid = (error) => {
    const errorCode = getErrorCode(error);
    return (hasErrorMessage(error, ACCESS_DENIED_TO_THIS_FIELD)
        || errorCode === "INVALID_TOKEN"
        || errorCode === "TOKEN_EXPIRED")
}

export const handleErrorMessageAndLogoutIfNecessary = (error, handleSignOut) => {
    console.log(error)
    const errorHasTimeOutExceeded = hasErrorMessage(error, TIMEOUT_EXCEEDED)

    if (tokenIsNotValid(error)) {
        handleSignOut()
        return
    }

    if (errorHasTimeOutExceeded) {
        return TEXT_ERROR.textTimeOutError;
    }

    if (hasErrorCode(error)) {
        switch (getErrorCode(error)) {
            case "ACCOUNT_NOT_ACTIVATED":
                return TEXT_ERROR.textAccountNotActivated;
            case "BAD_CREDENTIALS":
                return TEXT_ERROR.textBadCredentials;
            case "EMAIL_ALREADY_USED":
                return TEXT_ERROR.textEmailAlreadyUsed;
            case "UPLOAD_FAILED":
                return TEXT_ERROR.textDownloadError;
            case "INVALID_EMAIL":
                return TEXT_ERROR.invalidEmail;
            default :
                return TEXT_ERROR.textDefaultError;
        }
    } else {
        return TEXT_ERROR.textDefaultError;
    }
};

