import {useContext, useEffect, useState} from "react";

import dayjs from 'dayjs';
import {useLazyQuery, useQuery} from "@apollo/client";
import {Button, Grid} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import {toast} from "react-toastify";
import {pdf} from "@react-pdf/renderer";
import html2canvas from "html2canvas";
import Cookies from "js-cookie";
import {CSVLink} from "react-csv";

import HeaderComponent from "../components/HeaderComponent";
import StatsResultComponent from "../components/StatsResultComponent";
import DatePickerComponent from "../components/DatePickerComponent";
import DataGridStatsComponent from "../components/DataGridStatsComponent";
import LineChartComponent from "../components/LineChartComponent";
import VerticalNavBarComponent from "../components/VerticalNavBarComponent";
import ToastComponent from "../components/ToastComponent";
import PDFContentComponent from "../components/PDFContentComponent";

import {calculatePercentageChange} from "../utils/calculatePercentageChange";
import {formatDateString} from "../utils/formatDateString";
import {handleErrorMessageAndLogoutIfNecessary} from "../utils/handleErrorMessageAndLogoutIfNecessary";
import {API_GATEWAY_API, RECIPE_API} from "../gql/clients";
import {GET_STATS} from "../gql/apiGatewayQueries";
import {GET_RECIPES_STATS} from "../gql/recipeQueries";
import {GENERAL_TEXT} from "../constants/text";

import {BackOfficeContext} from "../AppRouting";
import {COLORS} from "../constants/colors";

export default function MarketingPage() {
    const {handleSignOut} = useContext(BackOfficeContext);

    const [username, setUsername] = useState(null)

    const [startDate, setStartDate] = useState(dayjs().subtract(7, 'day'));
    const [endDate, setEndDate] = useState(dayjs());

    const [prevGlobalStats, setPrevGlobalStats] = useState(null)
    const [globalStats, setGlobalStats] = useState(null)
    const [recipesStats, setRecipesStats] = useState(null)

    const [csvData, setCsvData] = useState([[]])

    const [isPrevStatsCall, setIsPrevStatsCall] = useState(false);

    const [prevGlobalStatsCounters, setPrevGlobalStatsCounters] = useState({
        likes: 0,
        dislikes: 0,
        follows: 0
    })

    const [globalStatsCounters, setGlobalStatsCounters] = useState({
        likes: 0,
        dislikes: 0,
        follows: 0
    })

    const [percentages, setPercentages] = useState({
        likes: 0,
        dislikes: 0,
        follows: 0
    })

    const sxButtonReport = {
        backgroundColor: COLORS.black,
        color: COLORS.white,
        borderColor: COLORS.black,
        '&:hover': {
            backgroundColor: COLORS.black,
            color: COLORS.white,
            borderColor: COLORS.black,
            opacity: 0.5
        }
    }

    const sxButtonData = {
        backgroundColor: COLORS.white,
        color: COLORS.black,
        borderColor: COLORS.black,
        '&:hover': {
            borderColor: COLORS.black,
            backgroundColor: COLORS.white,
            opacity: 0.5
        }
    }

    const [getGlobalStats] = useLazyQuery(GET_STATS, {
        client: API_GATEWAY_API,
        onCompleted: (data) => {
            updateGlobalStats(data.getStats, isPrevStatsCall);
        },
        onError: (error) => toast.error(handleErrorMessageAndLogoutIfNecessary(error, handleSignOut)),
    });

    useQuery(GET_RECIPES_STATS, {
        client: RECIPE_API,
        variables: {
            mine: true
        },
        onCompleted: (data) => {
            setRecipesStats(data.searchRecipes.edges)
        },
        onError: (error) => toast.error(handleErrorMessageAndLogoutIfNecessary(error, handleSignOut)),
    });

    useEffect(() => {
        setUsername(Cookies.get('username'))
    }, [])

    useEffect(() => {
        getData()
    }, [startDate, endDate]);

    useEffect(() => {
        updatePercentage()
    }, [globalStatsCounters, prevGlobalStatsCounters]);

    const getData = async () => {
        const dayBetweenDates = endDate.diff(startDate, "day");
        const prevStartDate = startDate.subtract(dayBetweenDates + 1, 'day');

        await setIsPrevStatsCall(false);

        await getGlobalStats({
            variables: {
                from: startDate,
                to: endDate
            }
        });

        await setIsPrevStatsCall(true);

        await getGlobalStats({
            variables: {
                from: prevStartDate,
                to: startDate.subtract(1, "day")
            }
        });
    };

    const handleStartDateChange = async (date) => {
        if (endDate >= date) {
            setStartDate(date);
        } else {
            setStartDate(date);
            setEndDate(date);
        }
    };

    const handleEndDateChange = async (date) => {
        if (date >= startDate) {
            setEndDate(date);
        } else {
            setEndDate(date);
            setStartDate(date);
        }
    };

    const updateGlobalStats = async (data, isPrevStatsCall) => {
        const likes = data.likes.reduce((total, el) => total + el.value, 0);
        const dislikes = data.dislikes.reduce((total, el) => total + el.value, 0);
        const follows = data.follows.reduce((total, el) => total + el.value, 0);

        if (isPrevStatsCall) {
            setPrevGlobalStats(data)
            setPrevGlobalStatsCounters({
                likes,
                dislikes,
                follows
            });
        } else {
            setGlobalStats(data)

            setGlobalStatsCounters({
                likes,
                dislikes,
                follows
            });

            updateCSVData(data)
        }
    };

    const updateCSVData = (data) => {
        const newCSVData = [[GENERAL_TEXT.date, GENERAL_TEXT.numberOfLikes, GENERAL_TEXT.numberOfDislikes, GENERAL_TEXT.numberOfFollows]];

        data.likes.forEach((el) => {
            newCSVData.push([el.date, el.value]);
        });

        data.dislikes.forEach((el, index) => {
            newCSVData[index + 1].push(el.value);
        });

        data.follows.forEach((el, index) => {
            newCSVData[index + 1].push(el.value);
        });

        setCsvData(newCSVData);
    };

    const updatePercentage = () => {
        setPercentages({
            likes: calculatePercentageChange(prevGlobalStatsCounters.likes, globalStatsCounters.likes),
            dislikes: calculatePercentageChange(prevGlobalStatsCounters.dislikes, globalStatsCounters.dislikes),
            follows: calculatePercentageChange(prevGlobalStatsCounters.follows, globalStatsCounters.follows),
        })
    };

    const exportToPDF = async () => {
        const lineChart = convertToImage("line-chart")

        const pdfContent = (
            <PDFContentComponent
                username={username}
                startDate={startDate.toString()}
                endDate={endDate.toString()}
                lineChart={lineChart}
                globalStatsCounters={globalStatsCounters}
                percentages={percentages}
                dataForTable={recipesStats}
            />
        );

        const blob = await pdf(pdfContent).toBlob();

        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `RM_Swipeat_${formatDateString(startDate)}_${formatDateString(endDate)}.pdf`);
        link.click();

        URL.revokeObjectURL(url);
    };

    const convertToImage = async (id) => {
        const imageContent = document.getElementById(id);
        const canvas = await html2canvas(imageContent);

        return canvas.toDataURL("image/png");
    };

        return (
            <>
                <HeaderComponent/>
                <Grid container xs direction="row" justifyContent="center" alignItems="flex-start">
                    <Grid container alignItems="center" xs={2}>
                        <VerticalNavBarComponent/>
                    </Grid>
                    <Grid container direction="column" alignItems="center" px={2} xs={10} mt={10}>
                        <Grid container xs direction="row" alignItems="space-between">
                            <Grid container xs={8} spacing={2} direction="row">
                                <Grid item xs={4}>
                                    <DatePickerComponent
                                        label={GENERAL_TEXT.startDate}
                                        value={startDate}
                                        onChange={handleStartDateChange}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <DatePickerComponent
                                        label={GENERAL_TEXT.endDate}
                                        value={endDate}
                                        onChange={handleEndDateChange}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container xs={4} justifyContent="end" alignItems="center">
                                <Grid item>
                                    <CSVLink
                                        separator={";"}
                                        data={csvData || []}
                                        filename={`DATA_Swipeat_${formatDateString(startDate)}_${formatDateString(endDate)}.csv`}
                                    >
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            onClick={() => {
                                            }}
                                            startIcon={<DownloadIcon/>}
                                            sx={sxButtonData}
                                        >
                                            {GENERAL_TEXT.data}
                                        </Button>
                                    </CSVLink>
                                </Grid>
                                <Grid item ml={2}>
                                    <Button variant="outlined" size="small" onClick={exportToPDF}
                                            startIcon={<DownloadIcon/>} sx={sxButtonReport}>
                                        {GENERAL_TEXT.report}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container xs direction="row" mt={0} ml={-2} spacing={2}>
                            <Grid item xs={3} ml={-1}>
                                <StatsResultComponent
                                    number={globalStatsCounters.likes}
                                    percentage={percentages.likes}
                                    text={GENERAL_TEXT.likesOnRecipes}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <StatsResultComponent
                                    number={globalStatsCounters.dislikes}
                                    percentage={percentages.dislikes}
                                    text={GENERAL_TEXT.dislikesOnRecipes}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <StatsResultComponent
                                    number={globalStatsCounters.follows}
                                    percentage={percentages.follows}
                                    text={GENERAL_TEXT.newFollowers}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" xs mt={2}>
                            <Grid item xs px={2} py={2} boxShadow={1} borderRadius={1}>
                                {globalStats &&
                                    <Grid item sx={{height: "100%", width: "100%"}} id="line-chart">
                                        <LineChartComponent
                                            globalStats={globalStats}
                                            prevGlobalStats={prevGlobalStats}
                                        />
                                    </Grid>
                                }
                            </Grid>
                            <Grid item xs px={2} py={2} boxShadow={1} borderRadius={1} ml={2}>
                                {recipesStats && <DataGridStatsComponent data={recipesStats}/>}
                            </Grid>
                        </Grid>
                    </Grid>
                    <ToastComponent/>
                </Grid>
            </>
        )
    ;
}
