import {Grid} from "@mui/material";
import {useNavigate} from "react-router-dom";

import HeaderComponent from "../components/HeaderComponent";
import ToastComponent from "../components/ToastComponent";
import VerticalNavBarComponent from "../components/VerticalNavBarComponent";
import ButtonComponent from "../components/ButtonComponent";

import {GENERAL_TEXT} from "../constants/text";

import DataGridTagsComponent from "../components/DataGridTagsComponent";
import {useQuery} from "@apollo/client";
import {GET_TAGS} from "../gql/recipeQueries";
import {RECIPE_API} from "../gql/clients";
import {useState} from "react";

export default function ListOfTags() {
    const navigate = useNavigate();

    const [listOfTags, setListOfTags] = useState([])
    const tagsValues = Object.values(listOfTags)

    useQuery(GET_TAGS, {
        client: RECIPE_API,
        onCompleted: (data) => setListOfTags(data.getTags),
    });

    return (
        <>
            <HeaderComponent/>
            <Grid container xs direction="row" justifyContent="center" alignItems="flex-start">
                <Grid container alignItems="center" xs={2}>
                    <VerticalNavBarComponent/>
                </Grid>
                <Grid container direction="column" alignItems="center" px={2} xs={10} mt={10}>
                    <Grid container xs alignItems="center" justifyContent="end">
                        <Grid item>
                            <ButtonComponent
                                label={GENERAL_TEXT.add}
                                onClick={() => navigate(`/editTag/${"newTag"}`)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs mt={2}>
                        <DataGridTagsComponent data={tagsValues}/>
                    </Grid>
                </Grid>
                <ToastComponent/>
            </Grid>
        </>
    );
}
