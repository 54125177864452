import React, {useContext, useEffect, useState} from "react";

import {useLazyQuery, useMutation} from "@apollo/client";
import {Checkbox, Grid, Typography} from "@mui/material";
import {Link, useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {SketchPicker} from "react-color";

import HeaderComponent from "../components/HeaderComponent";
import ToastComponent from "../components/ToastComponent";
import VerticalNavBarComponent from "../components/VerticalNavBarComponent";
import ButtonComponent from "../components/ButtonComponent";
import TextFieldComponent from "../components/TextFieldComponent";
import SelectComponent from "../components/SelectComponent";

import {handleErrorMessageAndLogoutIfNecessary} from "../utils/handleErrorMessageAndLogoutIfNecessary";
import {CREATE_TAG, DELETE_TAG, GET_TAG, UPDATE_TAG} from "../gql/recipeQueries";
import {RECIPE_API} from "../gql/clients";
import {GENERAL_TEXT, MODAL_TEXT} from "../constants/text";

import {BackOfficeContext} from "../AppRouting";
import {ITEMS_TAGS_TYPES} from "../constants/tagsTypes";
import {COLORS} from "../constants/colors";
import ModalComponent from "../components/ModalComponent";

export default function EditTagPage() {
    const navigate = useNavigate();

    const {handleSignOut} = useContext(BackOfficeContext);
    const {tagId} = useParams();

    const [type, setType] = useState("")
    const [name, setName] = useState("")
    const [icon, setIcon] = useState("")
    const [color, setColor] = useState("#000000")
    const [priority, setPriority] = useState(0)
    const [highlight, setHighlight] = useState(false)

    const [checkError, setCheckError] = useState(false);

    const [formValidation, setFormValidation] = useState({
        type: false,
        name: false,
        icon: false,
    })

    const [modal, setModal] = useState({
        visible: false,
        moderationReason: '',
    })

    const [getTag] = useLazyQuery(GET_TAG,
        {
            client: RECIPE_API,
            variables: {
                "getTagId": tagId
            },
            onCompleted: (data) => {
                updateTagToModify(data.getTag)
            },
            onError: (error) => toast.error(handleErrorMessageAndLogoutIfNecessary(error, handleSignOut)),
        }
    )

    const [createTag, {loading: loadingCreateTag}] = useMutation(CREATE_TAG, {
        client: RECIPE_API,
        onCompleted: () => navigate('/tags'),
        onError: (error) => toast.error(handleErrorMessageAndLogoutIfNecessary(error, handleSignOut)),
    });

    const [updateTag, {loading: loadingUpdateTag}] = useMutation(UPDATE_TAG, {
        client: RECIPE_API,
        onCompleted: () => navigate('/tags'),
        onError: (error) => toast.error(handleErrorMessageAndLogoutIfNecessary(error, handleSignOut)),
    });

    const [deleteTag] = useMutation(DELETE_TAG, {
        client: RECIPE_API,
        onCompleted: () => {
            navigate('/tags')
        },
        onError: (error) => toast.error(handleErrorMessageAndLogoutIfNecessary(error, handleSignOut))
    });

    useEffect(() => {
        const getData = async () => {
            await getTag()
        }
        if (tagId !== "newTag") {
            getData()
        }
    }, [])

    const updateTagToModify = (data) => {
        setType(data.type)
        setName(data.name)
        setIcon(data.icon)
        setColor(data.color)
        setPriority(data.priority)
        setHighlight(data.highlight)

        setFormValidation({
            type: true,
            name: true,
            icon: true,
        })
    }


    const handleChangeInput = (event, setData, number, inputName) => {
        if (number) {
            event.target.value >= 0 && setData(parseInt(event.target.value))
        } else {
            setData(event.target.value)
        }

        if (inputName) {
            setFormValidation((prevFormValidationStep) => ({
                ...prevFormValidationStep,
                [inputName]: event.target.value.length > 1,
            }));
        }
    }

    const handleChangeColor = (selectedColor) => {
        setColor(selectedColor.hex);
    };

    const handleChangeHighlight = (event) => {
        setHighlight(event.target.checked);
    };

    const formIsValid = () => {
        return Object.values(formValidation).every((value) => value === true)
    }

    const handleConfirm = async () => {
        if (formIsValid()) {
            const variables = {
                input: {
                    type: type,
                    name: name,
                    color: color,
                    icon: icon,
                    priority: priority,
                    highlight: highlight
                },
            };
            if (tagId === "newTag") {
                await createTag({variables})
            } else {
                variables.input.id = tagId;
                await updateTag({variables})
            }
        } else {
            setCheckError(true)
        }
    };

    const deleteThisTag = async () => {
        await deleteTag({
            variables: {
                deleteTagId: tagId,
            }
        });
    }

    const handleCloseModal = () => {
        setModal({...modal, "visible": false})
    };


    return (
        <>
            <HeaderComponent/>
            <Grid container xs direction="row" justifyContent="center" alignItems="flex-start">
                <Grid container alignItems="center" xs={2}>
                    <VerticalNavBarComponent/>
                </Grid>
                <Grid container direction="column" alignItems="center" px={2} xs={10} mt={10}>
                    <Grid container xs={12} direction="row" spacing={2}>
                        <Grid item xs={8}>
                            <SelectComponent
                                label={GENERAL_TEXT.type}
                                value={type}
                                onChange={(event) => handleChangeInput(event, setType, false, "type")}
                                data={ITEMS_TAGS_TYPES}
                                error={checkError && !formValidation.type}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} direction="row" mt={4} spacing={2}>
                        <Grid item xs={4}>
                            <TextFieldComponent
                                id="tagName"
                                label={GENERAL_TEXT.name}
                                value={name}
                                onChange={(event) => handleChangeInput(event, setName, false, "name")}
                                maxLength={256}
                                error={checkError && !formValidation.name}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextFieldComponent
                                id="tagIcon"
                                label={GENERAL_TEXT.icon}
                                value={icon}
                                onChange={(event) => handleChangeInput(event, setIcon, false, "icon")}
                                maxLength={256}
                                error={checkError && !formValidation.icon}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} mt={4} alignItems="center" direction="row" spacing={2}>
                        <Grid item xs={4}>
                            <TextFieldComponent
                                number
                                id="tagPriority"
                                label={GENERAL_TEXT.priority}
                                value={priority}
                                onChange={(event) => handleChangeInput(event, setPriority, true)}
                            />
                        </Grid>
                        <Grid item ml={1}>
                            <Grid container direction="row" alignItems="center">
                                <Typography variant="body1" sx={{color: COLORS.orange}}>
                                    {GENERAL_TEXT.highlightText}
                                </Typography>
                                <Checkbox checked={highlight} onChange={handleChangeHighlight}
                                          style={{color: COLORS.orange}}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} direction="row" mt={6} ml={4}>
                        <SketchPicker
                            color={color}
                            onChange={handleChangeColor}
                            disableAlpha
                            presetColors={[COLORS.orange]}
                        />
                    </Grid>
                    <Grid container mt={6} ml={3}>
                        <Typography
                            variant="body2"
                            pr={20}
                            style={{color: COLORS.orange}}
                        >
                            {GENERAL_TEXT.iconNameWarning}
                            <Link to="https://icons.expo.fyi/Index" style={{textDecoration: "none"}}>
                                https://icons.expo.fyi/Index
                            </Link>
                        </Typography>
                    </Grid>
                    <Grid container mt={6} ml={3}>
                        <Grid item>
                            <ButtonComponent
                                variant="contained"
                                onClick={() => handleConfirm()}
                                label={GENERAL_TEXT.confirm}
                                loading={loadingCreateTag || loadingUpdateTag}
                            />
                        </Grid>
                        <Grid item ml={2}>
                            <ButtonComponent
                                variantText
                                label={GENERAL_TEXT.deleteThisTag}
                                onClick={() => setModal({
                                    ...modal,
                                    visible: true,
                                })}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <ToastComponent/>
                <ModalComponent
                    open={modal.visible}
                    onClose={handleCloseModal}
                    onConfirm={async () => {
                        await deleteThisTag()
                        setModal({...modal, "visible": false})
                    }}
                    titleModal={MODAL_TEXT.deleteTag}
                />
            </Grid>
        </>
    );
}
