import { gql } from "@apollo/client";
export const LOGIN = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      token
      roles
      id
      email
    }
  }
`;

export const IS_TOKEN_VALID = gql`
  query IsTokenValid($value: String!, $type: String!) {
    isTokenValid(value: $value, type: $type)
  }
`;

export const GET_USER_SETTINGS = gql`
query GetUserSettings {
  getUserSettings {
    label
    name
  }
}
`;

export const GET_USERS = gql`
query GetUsers {
  getUsers {
    id
    username
  }
}
`;