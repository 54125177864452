import PropTypes from "prop-types";
import React, {useState} from "react";

import {Grid} from "@mui/material";
import Modal from '@mui/material/Modal';
import ReactPlayer from "react-player";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import {COLORS} from "../constants/colors";

export default function MediaComponent(props) {
    const {media, isVideo} = props

    const [fullScreen, setFullScreen] = useState(false)
    const src = isVideo ? media.frame.publicUrl : media.publicUrl

    const styleImage = {
        borderRadius: 8,
        height: "50vh",
        marginBottom: "1vw",
        resize: fullScreen ? "cover" : "contain"
    }

    const toggleFullScreen = () => {
        setFullScreen(prevState => !prevState)
    }

    return (
        <>
            <Grid container xs onClick={toggleFullScreen} justifyContent="center" alignItems="center">
                {isVideo &&
                    <Grid item position="absolute">
                        <PlayCircleOutlineIcon
                            sx={{
                                width: 100,
                                height: 100,
                                color: COLORS.opaqueWhite80
                            }}
                        />
                    </Grid>
                }
                <img
                    src={src}
                    style={styleImage}
                    alt={media.publicUrl}
                />
            </Grid>
            <Modal open={fullScreen} onClose={toggleFullScreen}>
                <Grid
                    container
                    width="100vw"
                    height="100vh"
                    justifyContent="center"
                    alignItems="center"
                    sx={{backgroundColor: COLORS.opaqueBlack50}}
                    borderColor="transparent"
                    onClick={toggleFullScreen}
                >
                    {isVideo ?
                        <ReactPlayer
                            url={media.publicUrl}
                            controls
                            playing
                        />
                        :
                        <img
                            src={media.publicUrl}
                            style={styleImage}
                            alt={media.publicUrl}
                        />
                    }
                </Grid>
            </Modal>
        </>
    );
}


MediaComponent.propTypes = {
    media: PropTypes.object.isRequired,
    isVideo: PropTypes.bool
}
