import PropTypes from "prop-types";

import {
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Typography, Grid
} from "@mui/material";

import {COLORS} from "../constants/colors";
import {GENERAL_TEXT} from "../constants/text";

export default function SelectComponent(props) {
    const {label, disabled, value, onChange, data, error} = props;

    const sxInputLabel = {
        paddingRight: 1,
        color: error ? COLORS.red : COLORS.orange,
        backgroundColor: "white",
        "&.MuiInputLabel-shrink": {
            color: COLORS.orange,
        }
    }

    const sxMenuItem = {
        color: COLORS.orange,
    }

    return (
        <Grid container position="relative" sx={{opacity : disabled ? 0.5 : 1}}>
            <FormControl
                size="small"
                fullWidth
                sx={{
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                        borderColor: error ? "red !important" : COLORS.orange,
                        borderWidth: error ? "2px !important" : "1px !important",
                    },
                    "& .MuiInputLabel-outlined": {
                        color: error ? "red !important" : COLORS.orange,
                    },
                }}
            >
                <InputLabel id="demo-simple-select-label" sx={sxInputLabel}>
                    {label}
                </InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    label={label}
                    size="small"
                    onChange={onChange}
                    error={error}
                    disabled={disabled}
                    sx={{
                        "& .MuiSelect-icon": {
                            color: error ? "red !important" : COLORS.orange,
                        },
                    }}
                >
                    {data.map((el) => (
                        <MenuItem key={el.value} value={el.value} sx={sxMenuItem}>
                            {el.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {error &&
                <Grid item position="absolute" bottom={-20} left={18}>
                    <Typography variant="body-2" color="red">{GENERAL_TEXT.requiredField}</Typography>
                </Grid>
            }
        </Grid>
    );
}

SelectComponent.propTypes = {
    value: PropTypes.any,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    data: PropTypes.array,
    error: PropTypes.bool,
};
