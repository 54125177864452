export const ITEMS_UNITY = [
    {label: "Unité", value: null},
    {label: "g", value: "g"},
    {label: "kg", value: "kg"},
    {label: "ml", value: "ml"},
    {label: "cl", value: "cl"},
    {label: "dl", value: "dl"},
    {label: "l", value: "l"},
    {label: "c.à.s", value: "c.à.s"},
    {label: "c.à.c", value: "c.à.c"},
    {label: "noix", value: "noix"},
    {label: "pincée(s)", value: "pincée(s)"},
    {label: "gousse(s)", value: "gousse(s)"},
    {label: "sachet(s)", value: "sachet(s)"},
    {label: "tranche(s)", value: "tranche(s)"},
    {label: "goutte(s)", value: "goutte(s)"},
    {label: "feuille(s)", value: "feuille(s)"},
    {label: "tige(s)", value: "tige(s)"},
    {label: "tasse(s)", value: "tasse(s)"},
]