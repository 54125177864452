/** Check if the site is opened with a smartphone or not
 *
 * @returns {boolean}
 */
import  { useEffect, useState } from "react";
import {useNavigate} from "react-router-dom";

export const CheckIfIsSmartphone = () => {
    const navigate = useNavigate();
    const [isSmartphone, setIsSmartphone] = useState(window.innerWidth < 900);

    useEffect(() => {
        const handleResize = () => {
            setIsSmartphone(window.innerWidth < 600);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [navigate]);

    return isSmartphone;
};
