export const COLORS = {
    red: "#E03939",
    opaqueRed10 : "rgba(224,57,57,0.1)",
    blue: "#0070FF",
    grey: "#6F7C91",
    opaqueGrey80: "rgba(111,124,145,0.8)",
    opaqueGrey60: "rgba(111,124,145,0.6)",
    opaqueGrey10: "rgba(111,124,145,0.1)",
    white: "#ffffff",
    opaqueWhite60: "#ffffff99",
    opaqueWhite80: "rgba(255,255,255,0.8)",
    black: "#000000",
    opaqueBlack50 : "#00000080",
    orange: "#EB7736",
    orangeNavBar: "#FFECE1",
    opaqueOrange10: "rgba(235,119,54,0.1)",
    whiteOrange: "rgba(255,175,131,0.6)",
    bgOrangeColor: "rgba(235,119,54,0.1)",
    greyInput: "#E2E2E2",
    purple : "#BD21D7",
    opaquePurple60 : "rgba(189,33,215,0.6)",
    opaquePurple10 : "rgba(189,33,215,0.1)",
    blueOdeven : "#2AB9FF",
    opaqueBlueOdeven60 : "rgba(42,185,255,0.6)",
    opaqueBlueOdeven10 : "rgba(42,185,255,0.1)",
    green: "#49B92D",
    opaqueGreen60 : "rgba(73,185,45,0.6)",
    opaqueGreen10 : "rgba(73,185,45,0.1)",
};
