import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";

import {Grid, IconButton, TextField, Typography} from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {Search} from "@mui/icons-material";
import AddReactionIcon from '@mui/icons-material/AddReaction';
import EmojiPicker, {EmojiStyle} from "emoji-picker-react";

import {COLORS} from "../constants/colors";
import {GENERAL_TEXT} from "../constants/text";

import '../assets/css/components/TextFieldComponent.css';

export default function TextFieldComponent(props) {
    const {
        id,
        label,
        password,
        maxLength,
        number,
        link,
        searchBar,
        emojiPicker,
        rows,
        multiline,
        value,
        size,
        onChange,
        onKeyDown,
        error,
        onAddEmoji,
    } = props

    const [showPassword, setShowPassword] = useState(false);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [endAdornment, setEndAdornment] = useState(null);

    useEffect(() => {
        if (password) {
            setEndAdornment(
                <Grid item position="absolute" right={0}>
                    <IconButton onClick={toggleShowPassword}>
                        {showPassword ? <VisibilityOff className="text-field-icon"/> :
                            <Visibility className="text-field-icon"/>}
                    </IconButton>
                </Grid>
            );
        } else if (emojiPicker) {
            setEndAdornment(
                <Grid item position="absolute" right={0}>
                    <IconButton onClick={toggleShowEmojiPicker}>
                        <AddReactionIcon className={error ? "text-field-icon-error" : "text-field-icon"}/>
                    </IconButton>
                </Grid>);
        } else if (searchBar) {
            setEndAdornment(<Search className="text-field-icon"/>);
        }
    }, [password, showPassword, searchBar, setEndAdornment])

    const handleChangeType = () => {
        if (number) {
            return "number";
        }
        return password && !showPassword ? "password" : "text";
    };

    const handleChangeTextError = () => {
        if (link) {
            return GENERAL_TEXT.linkNOK;
        }
        return number ? GENERAL_TEXT.requiredField : GENERAL_TEXT.requiredField2car
    };

    const toggleShowPassword = () => {
        setShowPassword((showPassword) => !showPassword)
    };

    const toggleShowEmojiPicker = () => {
        setShowEmojiPicker((showEmojiPicker) => !showEmojiPicker)
    };

    return (
        <Grid container position="relative" height="100%">
            <TextField
                id={id}
                type={handleChangeType()}
                variant="outlined"
                label={label}
                value={value}
                size={size || "small"}
                onChange={onChange}
                multiline={multiline}
                rows={rows || 1}
                inputProps={{maxLength: maxLength || 512}}
                InputProps={{endAdornment: endAdornment}}
                fullWidth
                error={error}
                onKeyDown={onKeyDown}
                sx={{
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                        borderColor: error ? "red !important" : COLORS.orange,
                        borderWidth: error ? "2px !important" : "1px !important",
                    },
                    "& .MuiInputLabel-outlined": {
                        color: error ? "red !important" : COLORS.orange,
                    },
                    ...(handleChangeType() === "number" && {
                        "& input[type=number]": {
                            color: error ? "red !important" : COLORS.orange,
                        },
                    }),
                }}
            />
            {showEmojiPicker &&
                <Grid item xs position="absolute" zIndex={99} top={0} right={50} pb={10}>
                    <EmojiPicker
                        emojiStyle={EmojiStyle.GOOGLE}
                        onEmojiClick={(emojiData) => onAddEmoji(emojiData)}
                    />
                </Grid>
            }
            {error &&
                <Grid item position="absolute" bottom={-20} left={18}>
                    <Typography
                        variant="body-2"
                        color="red"
                    >
                        {handleChangeTextError()}
                    </Typography>
                </Grid>
            }
        </Grid>
    );
}


TextFieldComponent.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    password: PropTypes.bool,
    number: PropTypes.bool,
    link: PropTypes.bool,
    maxLength: PropTypes.number,
    searchBar: PropTypes.bool,
    emojiPicker: PropTypes.bool,
    value: PropTypes.any.isRequired,
    size: PropTypes.string,
    rows: PropTypes.number,
    multiline: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func,
    onAddEmoji: PropTypes.func,
    error: PropTypes.bool,
}
