import React, {useContext, useEffect, useState} from "react";

import {Grid, IconButton, Step, StepLabel, Stepper, Typography} from "@mui/material";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {useNavigate, useParams} from "react-router-dom";
import dayjs from "dayjs";

import HeaderComponent from "../components/HeaderComponent";
import VerticalNavBarComponent from "../components/VerticalNavBarComponent";
import ToastComponent from "../components/ToastComponent";
import TextFieldComponent from "../components/TextFieldComponent";
import ButtonComponent from "../components/ButtonComponent";
import TimePickerComponent from "../components/TimePickerComponent";
import SelectComponent from "../components/SelectComponent";
import {MediaPickerComponent} from "../components/MediaPickerComponent";
import {SelectionOfTagsComponent} from "../components/SelectionOfTagsComponent";
import {toast} from "react-toastify";

import {handleErrorMessageAndLogoutIfNecessary} from "../utils/handleErrorMessageAndLogoutIfNecessary";
import {COLORS} from "../constants/colors";
import {GENERAL_TEXT} from "../constants/text";
import {ITEMS_COST} from "../constants/cost";
import {ITEMS_DIFFICULTY} from "../constants/difficulty";
import {AddCircleOutline, DeleteOutline} from "@mui/icons-material";
import {ITEMS_UNITY} from "../constants/unity";
import {API_GATEWAY_API, RECIPE_API} from "../gql/clients";
import {CREATE_RECIPE, UPDATE_RECIPE} from "../gql/recipeQueries";
import {GET_RECIPE, SEARCH_PRODUCTS} from "../gql/apiGatewayQueries";

import {BackOfficeContext} from "../AppRouting";

const stepperSteps = [
    GENERAL_TEXT.presentation,
    GENERAL_TEXT.ingredients,
    GENERAL_TEXT.steps,
    GENERAL_TEXT.medias,
    GENERAL_TEXT.tags
];

const sxStepper = {
    '& .MuiStepLabel-root .Mui-completed': {
        color: COLORS.orange,
        opacity: 0.8
    },
    '& .MuiStepLabel-root .Mui-active': {
        color: COLORS.orange,
    }
}

const sxButton = {
    border: 1,
    width: "100%",
    borderRadius: 20,
    borderColor: COLORS.orange,
    backgroundColor: COLORS.opaqueOrange10
}

export default function EditRecipePage(props) {
    const {isProAccount} = props

    const {handleSignOut, listOfTags} = useContext(BackOfficeContext);

    const navigate = useNavigate();
    const {recipeId} = useParams();

    useEffect(() => {
        const getData = async () => {
            await getRecipe()
        }
        if (recipeId !== "newRecipe") {
            getData()
        }
    }, [])

    useQuery(SEARCH_PRODUCTS, {
        variables: {
            mine: true
        },
        client: API_GATEWAY_API,
        onCompleted: (data) => {
            updateProducts(data.searchProducts.edges);
        },
        onError: (error) => toast.error(handleErrorMessageAndLogoutIfNecessary(error, handleSignOut)),
    });

    const [getRecipe] = useLazyQuery(GET_RECIPE,
        {
            client: API_GATEWAY_API,
            variables: {
                "getRecipeId": recipeId
            },
            onCompleted: (data) => {
                updateRecipeToModify(data.getRecipe)
            },
            onError: (error) => toast.error(handleErrorMessageAndLogoutIfNecessary(error, handleSignOut)),
        }
    )


    const updateRecipeToModify = async (data) => {
        const ingredientsWithoutProductDetails = data.ingredients

        await ingredientsWithoutProductDetails.forEach((el) => {
            if (el.product) {
                el.product = el.product.id;
            }
        })

        setName(data.name)
        setDescription(data.description)
        data.preparationTime && setPreparationTime(convertToDayjs(data.preparationTime))
        data.cookingTime && setCookingTime(convertToDayjs(data.cookingTime))
        data.restTime && setRestTime(convertToDayjs(data.restTime))
        setForPeople(data.forPeople)
        setCost(data.cost)
        setDifficulty(data.difficulty)

        setIngredients(ingredientsWithoutProductDetails)

        setSteps(data.steps)


        setSelectedImages(data.images)
        setImages(data.imageIds)
        setVideos(data.videoIds || [])
        setSelectedVideos(data.videos || [])

        const tagsIDsOfRecipe = data.tags?.map((el) => el.id) || [];

        setTags(tagsIDsOfRecipe)

        setFormValidationStep({
            0: {
                recipeName: true,
                forPeople: true,
                cost: true,
                difficulty: true,
            }
        })
    }


    const [activeStep, setActiveStep] = useState(0);
    const [checkError, setCheckError] = useState(false);

    //STEP 0
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [preparationTime, setPreparationTime] = useState(dayjs().startOf("day"));
    const [cookingTime, setCookingTime] = useState(dayjs().startOf("day"));
    const [restTime, setRestTime] = useState(dayjs().startOf("day"));
    const [cost, setCost] = useState("");
    const [difficulty, setDifficulty] = useState("");
    const [forPeople, setForPeople] = useState(0);
    const [duration, setDuration] = useState({
        preparation: {
            hours: 0,
            minutes: 0,
        },
        cooking: {
            hours: 0,
            minutes: 0,
        },
        rest: {
            hours: 0,
            minutes: 0,
        },
    })

    const [formValidationStep, setFormValidationStep] = useState({
        0: {
            recipeName: false,
            forPeople: false,
            cost: false,
            difficulty: false,
        }
    })

    useEffect(() => {
        setDuration({
            preparation: {
                hours: preparationTime.$H,
                minutes: preparationTime.$m,
            },
            cooking: {
                hours: cookingTime.$H,
                minutes: cookingTime.$m,
            },
            rest: {
                hours: restTime.$H,
                minutes: restTime.$m,
            },
        })
    }, [preparationTime, cookingTime, restTime])

    const convertToDayjs = (timeString) => {
        const [hours, minutes] = timeString.split(':').map(Number);

        const currentDate = new Date();
        const dateWithTime = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate(),
            hours,
            minutes
        );

        return dayjs(dateWithTime);
    };

    const handleChangeTimeInput = (value, setData) => {
        setData(value)
    };

    //STEP 1
    const [products, setProducts] = useState(null);
    const [ingredients, setIngredients] = useState([
        {name: '', quantity: null, unit: null, product: null},
    ]);

    const updateProducts = (data) => {
        if (data.length !== 0) {
            const itemProduct = [{value: null, label: "Aucun"}]
            data.forEach((el) => {
                itemProduct.push({value: el.node.id, label: el.node.name})
            })
            setProducts(itemProduct)
        }
    }

    const handleChangeIngredients = (event, index, type) => {
        const newIngredients = [...ingredients];
        newIngredients[index] = {
            ...newIngredients[index],
            [type]: type === "quantity" ? parseFloat(event.target.value) : event.target.value,
        };

        setIngredients(newIngredients);
    };

    const handleAddLineIngredients = () => {
        setIngredients((prevIngredients) => [
            ...prevIngredients,
            {name: '', quantity: null, unit: null, product: null}
        ]);
    };

    const handleDeleteLineIngredients = (index) => {
        setIngredients([
            ...ingredients.slice(0, index),
            ...ingredients.slice(index + 1, ingredients.length),
        ]);
    };


    // STEP 2
    const [steps, setSteps] = useState([
        {description: ''},
    ]);

    const handleChangeSteps = (event, index) => {
        const newSteps = [...steps];
        newSteps[index] = {
            ...newSteps[index],
            description: event.target.value,
        };

        setSteps(newSteps);
    };

    const handleAddLineSteps = () => {
        setSteps((prevSteps) => [
            ...prevSteps,
            {description: ''}
        ]);
    };

    const handleDeleteLineSteps = (index) => {
        setSteps([
            ...steps.slice(0, index),
            ...steps.slice(index + 1, steps.length),
        ]);
    };


    //STEP 3
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedVideos, setSelectedVideos] = useState([]);
    const [images, setImages] = useState([]);
    const [videos, setVideos] = useState([]);
    const [loadingDrive, setLoadingDrive] = useState(false)
    const [tags, setTags] = useState([])


    //ALL
    const thisStepIsValid = () => {
        if (activeStep === 0) {
            return Object.values(formValidationStep[0]).every((value) => value === true);
        }
        if (activeStep === 1) {
            return ingredients.every((value) => value.name.length > 1);
        }
        if (activeStep === 2) {
            return steps.every((value) => value.description.length > 1);
        }
        if (activeStep === 3) {
            return (images.length !== 0) && (images.length === selectedImages.length)
        }
        if (activeStep === 4) {
            return true
        }
    };

    const handleNext = (publicRecipe) => {
        if (thisStepIsValid()) {
            setCheckError(false)
            activeStep === 4 ? finalizeEdition(publicRecipe) : setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
            setCheckError(true)
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleChangeInput = (event, setData, format, inputName) => {
        setData(event.target.value)

        if (format === "text") {
            setFormValidationStep((prevFormValidationStep) => ({
                ...prevFormValidationStep,
                [activeStep]: {
                    ...prevFormValidationStep[activeStep],
                    [inputName]: event.target.value.length > 1,
                },
            }));
        }
        if (format === "numeric") {
            setFormValidationStep((prevFormValidationStep) => ({
                ...prevFormValidationStep,
                [activeStep]: {
                    ...prevFormValidationStep[activeStep],
                    [inputName]: event.target.value > 0,
                },
            }));
        }
        if (format === "select") {
            setFormValidationStep((prevFormValidationStep) => ({
                ...prevFormValidationStep,
                [activeStep]: {
                    ...prevFormValidationStep[activeStep],
                    [inputName]: !!event.target.value,
                },
            }));
        }
    }

    const [createRecipe, {loading: loadingCreateRecipe}] = useMutation(CREATE_RECIPE, {
        client: RECIPE_API,
        onCompleted: () => {
            navigate('/ourRecipes')
        },
        onError: (error) => toast.error(handleErrorMessageAndLogoutIfNecessary(error, handleSignOut)),
    });

    const [updateRecipe, {loading: loadingUpdateRecipe}] = useMutation(UPDATE_RECIPE, {
        client: RECIPE_API,
        onCompleted: () => {
            navigate(`/recipe/${recipeId}`)
        },
        onError: (error) => toast.error(handleErrorMessageAndLogoutIfNecessary(error, handleSignOut)),
    });

    const convertArrayTimeOnStringTime = (duration) => {
        const preparation = `${duration.preparation.hours}:${duration.preparation.minutes}`;
        const cooking = `${duration.cooking.hours}:${duration.cooking.minutes}`;
        const rest = `${duration.rest.hours}:${duration.rest.minutes}`;
        return [preparation, cooking, rest];
    };

    const finalizeEdition = async (publicRecipe) => {
        const arrayTime = convertArrayTimeOnStringTime(duration);

        const variables = {
            input: {
                name: name,
                description: description,
                images: images,
                videos : videos,
                forPeople: parseInt(forPeople),
                difficulty: parseInt(difficulty),
                cost: parseInt(cost),
                preparationTime: arrayTime[0],
                cookingTime: arrayTime[1],
                restTime: arrayTime[2],
                ingredients: ingredients,
                steps: steps,
                tags: tags
            },
        };

        if (isProAccount) {
            variables.input.public = publicRecipe;
        }

        if (recipeId === "newRecipe") {
            await createRecipe({variables})
        } else {
            variables.input.id = recipeId;
            await updateRecipe({variables})
        }
    }

    const handleDetermineLabelButton = () => {
        if (!isProAccount) {
            return activeStep === stepperSteps.length - 1 ? GENERAL_TEXT.modify : GENERAL_TEXT.next
        }
        return activeStep === stepperSteps.length - 1 ? GENERAL_TEXT.shareOnPublic : GENERAL_TEXT.next
    }

    return (
        <>
            <HeaderComponent/>
            <Grid container xs direction="row" justifyContent="center" alignItems="flex-start">
                <Grid container alignItems="center" xs={2}>
                    <VerticalNavBarComponent/>
                </Grid>
                <Grid container direction="column" justifyContent="center" alignItems="center" xs={10} px={3} mt={10}
                      mb={20}>
                    <Grid container xs px={1}>
                        <Stepper activeStep={activeStep} sx={[sxStepper, {width: "100%"}]}>
                            {stepperSteps.map((label, index) => {
                                return (
                                    <Step key={index}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Grid>
                    <Grid container xs>
                        {activeStep === 0 && (
                            <Grid container xs direction="column" mt={4} justifyContent="center" alignItems="center">
                                <Grid container xs direction="column" spacing={4}>
                                    <Grid item xs>
                                        <TextFieldComponent
                                            id="recipeName"
                                            label={GENERAL_TEXT.recipeNameRequired}
                                            value={name}
                                            onChange={(event) => handleChangeInput(event, setName, "text", "recipeName")}
                                            error={checkError && !formValidationStep[0].recipeName}
                                            maxLength={256}
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <TextFieldComponent
                                            id="description"
                                            label={GENERAL_TEXT.description}
                                            value={description}
                                            onChange={(event) => handleChangeInput(event, setDescription)}
                                            multiline
                                            rows={5}
                                            maxLength={512}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container xs direction="row" mt={4} columnSpacing={4}>
                                    <Grid item xs={4}>
                                        <TimePickerComponent
                                            label={GENERAL_TEXT.preparationTime}
                                            value={preparationTime}
                                            onChange={(value) => handleChangeTimeInput(value, setPreparationTime)}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TimePickerComponent
                                            label={GENERAL_TEXT.cookingTime}
                                            value={cookingTime}
                                            onChange={(value) => handleChangeTimeInput(value, setCookingTime)}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TimePickerComponent
                                            label={GENERAL_TEXT.restTime}
                                            value={restTime}
                                            onChange={(value) => handleChangeTimeInput(value, setRestTime)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container xs direction="row" mt={4} columnSpacing={4}>
                                    <Grid item xs={4}>
                                        <TextFieldComponent
                                            number
                                            id="forPeople"
                                            label={GENERAL_TEXT.forPeopleRequired}
                                            value={forPeople}
                                            onChange={(event) => {
                                                if (event.target.value >= 0) handleChangeInput(event, setForPeople, "numeric", "forPeople")
                                            }}
                                            error={checkError && !formValidationStep[0].forPeople}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <SelectComponent
                                            label={GENERAL_TEXT.costRequired}
                                            value={cost}
                                            onChange={(event) => handleChangeInput(event, setCost, "select", "cost")}
                                            data={ITEMS_COST}
                                            error={checkError && !formValidationStep[0].cost}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <SelectComponent
                                            label={GENERAL_TEXT.difficultyRequired}
                                            value={difficulty}
                                            onChange={(event) => handleChangeInput(event, setDifficulty, "select", "difficulty")}
                                            data={ITEMS_DIFFICULTY}
                                            error={checkError && !formValidationStep[0].difficulty}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        {activeStep === 1 && (
                            <Grid container xs direction="row" mt={4} px={1.5} justifyContent="center"
                                  alignItems="center">
                                {ingredients.map((ingredient, index) => {
                                    const onlyOneLine = index === 0 && index === ingredients.length - 1
                                    return (
                                        <>
                                            <Grid container xs={12} mb={4} direction="row" columnSpacing={1}
                                                  justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={products ? 5 : 6}>
                                                    <TextFieldComponent
                                                        id="ingredientName"
                                                        label={GENERAL_TEXT.name}
                                                        value={ingredients[index].name}
                                                        onChange={(event) => handleChangeIngredients(event, index, "name")}
                                                        error={ingredient.name.length < 2 && checkError}
                                                        maxLength={256}
                                                    />
                                                </Grid>
                                                <Grid item xs={products ? 1 : 2}>
                                                    <TextFieldComponent
                                                        id="ingredientQuantity"
                                                        label={GENERAL_TEXT.quantity}
                                                        value={ingredients[index].quantity || ''}
                                                        number
                                                        onChange={(event) => handleChangeIngredients(event, index, "quantity")}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <SelectComponent
                                                        data={ITEMS_UNITY}
                                                        id="ingredientUnit"
                                                        label={GENERAL_TEXT.unity}
                                                        value={ingredients[index].unit || ''}
                                                        onChange={(event) => handleChangeIngredients(event, index, "unit")}
                                                    />
                                                </Grid>
                                                {products &&
                                                    <Grid item xs={2}>
                                                        <SelectComponent
                                                            data={products}
                                                            id="ingredientProduct"
                                                            label={GENERAL_TEXT.relatedProduct}
                                                            value={ingredients[index].product || ''}
                                                            onChange={(event) => handleChangeIngredients(event, index, "product")}
                                                        />
                                                    </Grid>
                                                }
                                                <Grid item xs={2}>
                                                    <IconButton
                                                        onClick={() => handleDeleteLineIngredients(index)}
                                                        sx={{
                                                            ...sxButton,
                                                            opacity: onlyOneLine ? 0.5 : 1
                                                        }}
                                                        disabled={onlyOneLine}
                                                    >
                                                        <DeleteOutline sx={{color: COLORS.orange}}/>
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )
                                })}
                                <Grid container xs={12} mt={2} justifyContent="flex-start" alignItems="center">
                                    <Grid item xs={2}>
                                        <IconButton
                                            onClick={() => handleAddLineIngredients()}
                                            sx={sxButton}
                                        >
                                            <AddCircleOutline sx={{color: COLORS.orange}}/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        {activeStep === 2 && (
                            <Grid container xs direction="row" mt={4} px={1.5} justifyContent="center"
                                  alignItems="center">
                                {steps.map((step, index) => {
                                    const onlyOneLine = index === 0 && index === steps.length - 1
                                    return (
                                        <>
                                            <Grid container xs={12} mb={4} direction="row" spacing={1}
                                                  justifyContent="flex-start"
                                                  alignItems="center">
                                                <Grid item xs={10}>
                                                    <TextFieldComponent
                                                        id="stepDescription"
                                                        label={GENERAL_TEXT.step + (index + 1)}
                                                        value={steps[index].description}
                                                        onChange={(event) => handleChangeSteps(event, index)}
                                                        error={step.description.length < 2 && checkError}
                                                        multiline
                                                        rows={5}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <IconButton
                                                        onClick={() => handleDeleteLineSteps(index)}
                                                        sx={{
                                                            ...sxButton,
                                                            opacity: onlyOneLine ? 0.5 : 1
                                                        }}
                                                        disabled={onlyOneLine}
                                                    >
                                                        <DeleteOutline sx={{color: COLORS.orange}}/>
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )
                                })}
                                <Grid container xs={12} mt={2} justifyContent="flex-start" alignItems="center">
                                    <Grid item xs={2}>
                                        <IconButton
                                            onClick={() => handleAddLineSteps()}
                                            sx={sxButton}
                                        >
                                            <AddCircleOutline sx={{color: COLORS.orange}}/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        {activeStep === 3 && (
                            <Grid container xs direction="column" mt={4} px={2} justifyContent="center"
                                  alignItems="flex-start">
                                <Grid item>
                                    <Typography mb={2} color={COLORS.orange} variant="body2">
                                        {GENERAL_TEXT.pictures}
                                    </Typography>
                                    <MediaPickerComponent
                                        numberMaxOfMedias={6}
                                        selectedMedias={selectedImages}
                                        setSelectedMedias={setSelectedImages}
                                        setMedias={setImages}
                                        setLoadingDrive={setLoadingDrive}
                                        error={checkError && images.length === 0}
                                    />
                                </Grid>
                                <Grid item mt={4}>
                                    <Typography mb={2} color={COLORS.orange} variant="body2">
                                                  {GENERAL_TEXT.videos}
                                    </Typography>
                                    <MediaPickerComponent
                                        isVideo
                                        numberMaxOfMedias={3}
                                        selectedMedias={selectedVideos}
                                        setSelectedMedias={setSelectedVideos}
                                        setMedias={setVideos}
                                        setLoadingDrive={setLoadingDrive}
                                    />
                                </Grid>
                            </Grid>
                        )}
                        {activeStep === 4 && (
                            <Grid container xs direction="row" mt={4} px={2} justifyContent="center"
                                  alignItems="center">
                                <SelectionOfTagsComponent
                                    tags={tags}
                                    setTags={setTags}
                                    listOfTags={listOfTags}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Grid container xs justifyContent="flex-start" alignItems="center" mt={6} px={4}>
                        <Grid item ml={-2}>
                            <ButtonComponent
                                variant="contained"
                                onClick={() => handleNext(true)}
                                label={handleDetermineLabelButton()}
                                disabled={loadingDrive}
                                loading={loadingCreateRecipe || loadingUpdateRecipe}
                            />
                        </Grid>
                        {activeStep === stepperSteps.length - 1 && isProAccount &&
                            <Grid item ml={2}>
                                <ButtonComponent
                                    variant="contained"
                                    onClick={() => handleNext(false)}
                                    label={GENERAL_TEXT.shareOnPrivate}
                                    disabled={loadingDrive}
                                    loading={loadingCreateRecipe || loadingUpdateRecipe}
                                />
                            </Grid>
                        }
                        <Grid item ml={2}>
                            <ButtonComponent
                                variantText
                                label={GENERAL_TEXT.back}
                                disabled={activeStep === 0}
                                onClick={handleBack}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <ToastComponent/>
        </>
    )
}
