import {useContext, useState} from "react";

import {Grid} from "@mui/material";
import {useNavigate} from "react-router-dom";

import HeaderComponent from "../components/HeaderComponent";
import ToastComponent from "../components/ToastComponent";
import VerticalNavBarComponent from "../components/VerticalNavBarComponent";
import ButtonComponent from "../components/ButtonComponent";
import DataGridProductsComponent from "../components/DataGridProductsComponent";

import {GENERAL_TEXT} from "../constants/text";

import {BackOfficeContext} from "../AppRouting";
import {handleErrorMessageAndLogoutIfNecessary} from "../utils/handleErrorMessageAndLogoutIfNecessary";
import {API_GATEWAY_API} from "../gql/clients";
import {useQuery} from "@apollo/client";
import {SEARCH_PRODUCTS} from "../gql/apiGatewayQueries";
import {toast} from "react-toastify";

export default function ListOfProductsPage() {
    const {handleSignOut} = useContext(BackOfficeContext);

    const navigate = useNavigate();

    const [products, setProducts] = useState([]);

    useQuery(SEARCH_PRODUCTS, {
        variables: {
            mine: true
        },
        client: API_GATEWAY_API,
        onCompleted: (data) => {
            setProducts(data.searchProducts.edges);
        },
        onError: (error) => toast.error(handleErrorMessageAndLogoutIfNecessary(error, handleSignOut)),
    });

    return (
            <>
                <HeaderComponent/>
                <Grid container xs direction="row" justifyContent="center" alignItems="flex-start">
                    <Grid container alignItems="center" xs={2}>
                        <VerticalNavBarComponent/>
                    </Grid>
                    <Grid container direction="column" alignItems="center" px={2} xs={10} mt={10}>
                        <Grid container xs alignItems="center" justifyContent="end">
                                <Grid item>
                                    <ButtonComponent
                                        label={GENERAL_TEXT.add}
                                        onClick={() => navigate(`/editProduct/${"newProduct"}`)}
                                    />
                                </Grid>
                        </Grid>
                        <Grid container xs mt={2}>
                            <DataGridProductsComponent data={products}/>
                        </Grid>
                    </Grid>
                    <ToastComponent/>
                </Grid>
            </>
    );
}
