import {useContext} from "react";

import {Link, useLocation} from 'react-router-dom';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import CookieIcon from '@mui/icons-material/Cookie';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {Grid, Typography} from "@mui/material";

import {APPLICATION_NAME, COMPANY_NAME, GENERAL_TEXT, VERSION} from "../constants/text";
import {COLORS} from "../constants/colors";

import {BackOfficeContext} from "../AppRouting";

export default function VerticalNavBarComponent() {
    const {userRoles} = useContext(BackOfficeContext);

    const location = useLocation().pathname

    const recipePattern = /^\/recipe\/.*/;
    const editRecipePattern = /^\/editRecipe\/.*/;

    const productPattern = /^\/product\/.*/;
    const editProductPattern = /^\/editProduct\/.*/;

    const editTagPattern = /^\/editTag\/.*/;

    const reportsPattern = /^\/reports\/.*/;

    const sxButton = {
        '&:hover': {
            opacity: 0.5,
        }
    }

    return (
        <Grid container xs={2} bgcolor={COLORS.orangeNavBar} pt={12} top={0} position="fixed" height="100vh"
              direction="row" className="full-height-navbar" zIndex={99}>
            {userRoles.roleAdmin &&
                <Grid container direction="column" spacing={1} justifyContent="flex-start">
                    <Grid item>
                        <Link to="/moderation" style={{textDecoration: "none"}}>
                            <Grid
                                container
                                xs
                                px={2}
                                py={1}
                                direction="row"
                                color={COLORS.orange}
                                alignItems="center"
                                overflow="auto"
                                bgcolor={location === "/moderation" || editRecipePattern.test(location) || recipePattern.test(location) ? COLORS.whiteOrange : "transparent"}
                                sx={sxButton}
                            >
                                <ShowChartIcon/>
                                <Typography ml={1} variant="body2">{GENERAL_TEXT.moderationRecipes}</Typography>
                            </Grid>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link to="/tags" style={{textDecoration: "none"}}>
                            <Grid
                                container
                                xs
                                px={2}
                                py={1}
                                direction="row"
                                color={COLORS.orange}
                                alignItems="center"
                                overflow="auto"
                                bgcolor={location === "/tags" || editTagPattern.test(location) ? COLORS.whiteOrange : "transparent"}
                                sx={sxButton}
                            >
                                <LocalOfferIcon/>
                                <Typography ml={1} variant="body2">{GENERAL_TEXT.tagsManagement}</Typography>
                            </Grid>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link to="/notifications" style={{textDecoration: "none"}}>
                            <Grid
                                container
                                xs
                                px={2}
                                py={1}
                                direction="row"
                                color={COLORS.orange}
                                alignItems="center"
                                overflow="auto"
                                bgcolor={location === "/notifications" ? COLORS.whiteOrange : "transparent"}
                                sx={sxButton}
                            >
                                <NotificationsActiveIcon/>
                                <Typography ml={1} variant="body2">{GENERAL_TEXT.notifications}</Typography>
                            </Grid>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link to="/reports" style={{textDecoration: "none"}}>
                            <Grid
                                container
                                xs
                                px={2}
                                py={1}
                                direction="row"
                                color={COLORS.orange}
                                alignItems="center"
                                bgcolor={reportsPattern.test(location) || location === "/reports" ? COLORS.whiteOrange : "transparent"}
                                sx={sxButton}
                            >
                                <ErrorOutlineIcon/>
                                <Typography ml={1} variant="body2">{GENERAL_TEXT.reports}</Typography>
                            </Grid>
                        </Link>
                    </Grid>
                </Grid>
            }
            {userRoles.rolePro &&
                <Grid container direction="column" spacing={1} justifyContent="flex-start">
                    <Grid item>
                        <Link to="/marketing" style={{textDecoration: "none"}}>
                            <Grid
                                container
                                xs
                                px={2}
                                py={1}
                                direction="row"
                                color={COLORS.orange}
                                alignItems="center"
                                bgcolor={location === "/marketing" ? COLORS.whiteOrange : "transparent"}
                                sx={sxButton}
                            >
                                <ShowChartIcon/>
                                <Typography ml={1} variant="body2">{GENERAL_TEXT.marketing}</Typography>
                            </Grid>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link to="/ourRecipes" style={{textDecoration: "none"}}>
                            <Grid
                                container
                                xs
                                px={2}
                                py={1}
                                direction="row"
                                color={COLORS.orange}
                                alignItems="center"
                                bgcolor={editRecipePattern.test(location) || recipePattern.test(location) || location === "/ourRecipes" ? COLORS.whiteOrange : "transparent"}
                                sx={sxButton}
                            >
                                <CookieIcon/>
                                <Typography ml={1} variant="body2">{GENERAL_TEXT.recipes}</Typography>
                            </Grid>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link to="/ourProducts" style={{textDecoration: "none"}}>
                            <Grid
                                container
                                xs
                                px={2}
                                py={1}
                                direction="row"
                                color={COLORS.orange}
                                alignItems="center"
                                bgcolor={editProductPattern.test(location) || productPattern.test(location) || location === "/ourProducts" ? COLORS.whiteOrange : "transparent"}
                                sx={sxButton}
                            >
                                <LocalMallIcon/>
                                <Typography ml={1} variant="body2">{GENERAL_TEXT.products}</Typography>
                            </Grid>
                        </Link>
                    </Grid>
                </Grid>
            }
            <Grid item position="absolute" bottom={20} left={20} color={COLORS.orange} sx={sxButton}>
                <Link to="https://odeven.fr/" target="_blank" style={{textDecoration: "none"}}>
                    <Typography variant="body2" color={COLORS.orange}>
                        {APPLICATION_NAME} by {COMPANY_NAME}
                    </Typography>
                </Link>
                <Typography variant="body2">{VERSION}</Typography>
            </Grid>
        </Grid>
    );
}

