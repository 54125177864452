import {gql} from "@apollo/client";

export const SEARCH_RECIPES = gql`
  query SearchRecipes($mine: Boolean) {
  searchRecipes(mine: $mine) {
    edges {
      node {
        id
    name
    createdAt
    createdBy {
      username
    }
    moderatedAt
    moderatedBy {
      username
    }
    public
    status
    images {
      publicUrl
    }
      }
    }
  }
}
`;
export const SEARCH_PRODUCTS = gql`
  query SearchProducts($mine: Boolean) {
  searchProducts(mine: $mine) {
    edges {
      node {
        id
        name
        description
        link
        image {
          publicUrl
        }
      }
    }
  }
}
`;

export const GET_RECIPE = gql`
query GetRecipe($getRecipeId: ID!) {
  getRecipe(id: $getRecipeId) {
    id
    imageIds
    videoIds
    cookingTime
    cost
    createdAt
    createdBy {
      username
    }
    moderatedAt
    moderatedBy {
      username
    }
    description
    difficulty
    forPeople
    videos {
    publicUrl
    frame {
        publicUrl
      }
    }
    images {
      publicUrl
    }
    ingredients {
      name
      quantity
      unit
      product {
        id
        name
      }
    }
    name
    preparationTime
    public
    restTime
    status
    steps {
      description
    }
    tags {
    id
    name
    }
  }
}
`;

export const GET_PRODUCT = gql`
query GetProduct($getProductId: ID!) {
  getProduct(id: $getProductId) {
    id
    name
    description
    link
    imageId
    image {
      publicUrl
    }
  }
}
`;

export const GET_STATS = gql`
query GetStats($from: DateTime!, $to: DateTime!) {
  getStats(from: $from, to: $to) {
    dislikes {
      date
      value
    }
    follows {
      date
      value
    }
    likes {
      date
      value
    }
  }
}
`;

export const GET_PROFILE_INFORMATION = gql`
query GetUser($getUserId: ID) {
  getUser(id: $getUserId) {
    username
    profilePicture {
      publicUrl
    }
  }
}
`;

export const GET_REVIEW_REPORTS = gql`
query GetReports {
  getReports {
    review {
      createdBy {
        id
        username
      }
      id
      createdAt
      updatedAt
      message
      rating
      recipe {
        name
        id
      }
    }
    reason
    createdAt
    id
    createdBy {
      id
      username
    }
  }
}
`;
